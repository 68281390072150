import { getActiveUserMetrics } from "../redux/actions/metric.action";
import { get_metrics_data } from "./Metrics";

export const getRelatedProperty = async (metrics: any) => {
  let arr: any = [];
  let search = null;
  metrics.map((el: any) => {
    arr.push({ label: el.label, name: el.name, value: el.value });
    search = { type: el.type };
    return 0;
  });
  let related = await get_metrics_data(search, arr);
  return related.slice(0, 6);
};

export const matchedMates = async (records: any) => {
  let active_user = await getActiveUserMetrics();
  let user_metrics: any = active_user?.map((el: any) => el.name);
  let data = records.filter((el: any) =>
    user_metrics.some((il: any, index: any) => {
      el.rank = 0;
      return el.metrics.map((a: any) => a.name).includes(il);
    })
  );

  return data;
};
