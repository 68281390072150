import React from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "../../routes";
import Logo from "../../assets/img/logo-light.png";

type Props = {
  children: any;
};

const AuthComponent = ({ children }: Props) => {
  return (
    <main className="content-wrapper w-100 px-3 ps-lg-5 pe-lg-4 mx-auto">
      <div className="d-lg-flex justify-content-center">
        <div
          className="d-flex flex-column vh-100 w-100 py-4  me-lg-5"
          style={{ maxWidth: "416px" }}
        >
          <header className="navbar px-0 pb-4 mt-n2 mt-sm-0 mb-2 mb-md-3 mb-lg-4">
            <Link to={PAGES_URL.Landing} className="navbar-brand pt-0">
              <span className="d-flex flex-shrink-0 text-primary me-2">
                <img src={Logo} width={80} alt="" />
              </span>
            </Link>
          </header>
          {children}
          <footer className="mt-auto">
            <div className="nav mb-4">
              <Link className="nav-link text-decoration-underline p-0" to={"/"}>
                Need help?
              </Link>
            </div>
            <p className="fs-xs mb-0">
              © All rights reserved. Made by{" "}
              <span className="animate-underline">
                <Link
                  className="animate-target text-dark-emphasis text-decoration-none"
                  to={PAGES_URL.Landing}
                  target="_blank"
                  rel="noreferrer"
                >
                  Metricise
                </Link>
              </span>
            </p>
          </footer>
        </div>
      </div>
    </main>
  );
};

export default AuthComponent;
