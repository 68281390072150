import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
  series?: any;
  labels: any;
  plotOptions?: any;
  height?: any;
};

const RadialBar = ({ series, labels, plotOptions, ...props }: Props) => {
  const options: ApexOptions = {
    series,
    chart: {
      type: "radialBar",
      fontFamily: "",
    },
    plotOptions: plotOptions,
    labels,
    fill: {
      colors: ["#800411"],
    },
  };

  return (
    <div className="p-0 m-0">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        width={100}
        {...props}
      />
      <div id="chart"></div>
    </div>
  );
};

export default RadialBar;
