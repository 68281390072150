import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthComponent from "../../components/layouts/AuthComponent";
import PAGES_URL from "../../routes";
import { InputField, InputPasswordField } from "../../components/forms/Inputs";
import { SignUpValidator } from "../../lib/validators/auth.validator";
import { registerAction } from "../../redux/actions/auth.action";
import Button from "../../components/forms/Buttons";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { toast } from "react-toastify";

type Props = {
  fullname: string;
  email: string;
  password: string;
  metrics: any;
};

const SignUp = ({ signUp, metrics }: any) => {
  const User = {} as Props;
  const [state, setState] = useState<any>(User);
  const [error, setError] = useState<Props>(User);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    setLoading(true);
    setProgress(20);
    e.preventDefault();
    const errors = SignUpValidator(state);
    if (Object.keys(errors).length !== 0) {
      setProgress(100);
    }

    signUp(state).then((response: any) => {
      if (response?.__isAuthError) {
        setLoading(false);
        setProgress(100);
        toast.error("User already registered");
      } else {
        toast.success("You can now sign in to your account");
        setLoading(false);
        setProgress(100);
        navigate(PAGES_URL.Login);
      }
    });
    setError(User);
  };

  return (
    <AuthComponent>
      <LoaderComponent progress={progress} loading={loading} />
      <div className="mt-auto"></div>

      <h1 className="h2">Create an account</h1>
      <div className="nav fs-sm mb-3 mb-lg-4">
        I already have an account
        <Link
          className="nav-link text-decoration-underline p-0 ms-2"
          to={PAGES_URL.Login}
        >
          Sign in
        </Link>
      </div>
      <form className="needs-validation">
        <div className="position-relative mb-4">
          <label htmlFor="register-email" className="form-label">
            Full Name
          </label>
          <InputField
            onChange={handleInputChange}
            name="fullname"
            value={state.fullname}
            errorMessage={error.fullname}
          />
        </div>
        <div className="position-relative mb-4">
          <label htmlFor="register-email" className="form-label">
            Email
          </label>
          <InputField
            onChange={handleInputChange}
            name="email"
            value={state.email}
            errorMessage={error.email}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <InputPasswordField
            onChange={handleInputChange}
            name="password"
            value={state.password}
            errorMessage={error.password}
          />
        </div>
        <div className="d-flex flex-column gap-2 mb-4">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="privacy"
              required
            />
            <label htmlFor="privacy" className="form-check-label">
              I have read and accept the{" "}
              <Link className="text-dark-emphasis" to={"/"}>
                Privacy Policy
              </Link>
            </label>
          </div>
        </div>
        <Button
          loading={loading}
          className="btn-primary btn-lg w-100"
          onClick={handleSubmit}
        >
          Sign up
        </Button>
      </form>
    </AuthComponent>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  metrics: state.metric,
});

const mapDispatchToProps = (dispatch: any) => ({
  signUp: async (data: any) => dispatch(registerAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
