import React, { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name: string;
  errorMessage?: string;
}
const InputField = ({
  className,
  name,
  errorMessage,
  ...props
}: InputProps) => {
  return (
    <>
      <input
        type="email"
        className={`form-control form-control-lg ${className}  ${
          errorMessage && "border-danger"
        }`}
        id={name}
        name={name}
        {...props}
      />
      {errorMessage && (
        <div className="my-1 small text-danger">
          <BsInfoCircleFill /> {errorMessage}
        </div>
      )}
    </>
  );
};

interface InputPasswordFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name?: string;
  icon?: boolean;
  placeholder?: string;
  errorMessage?: string;
  value?: string;
}
const InputPasswordField = ({
  className,
  icon,
  errorMessage,
  ...props
}: InputPasswordFieldProps) => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <div className="position-relative">
        {icon && (
          <i
            className={`ai-lock-closed fs-lg position-absolute top-50 start-0 translate-middle-y ms-3`}
          ></i>
        )}
        <div className="password-toggle">
          <input
            className={`form-control form-control-lg ${className}  ${
              errorMessage && "border-danger"
            }`}
            type={toggler ? "text" : "password"}
            placeholder="Password"
            {...props}
            autoComplete="on"
          />
          <label
            className="password-toggle-btn"
            aria-label="Show/hide password"
          >
            <input className="password-toggle-check" type="checkbox" />
            <span
              className="password-toggle-indicator"
              onClick={() => setToggler(!toggler)}
            ></span>
          </label>
        </div>
      </div>

      {errorMessage && (
        <div className=" my-1 small text-danger">
          <BsInfoCircleFill /> {errorMessage}
        </div>
      )}
    </>
  );
};

export { InputField, InputPasswordField };
