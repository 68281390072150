/** @format */

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import propertyReducer from "./propertyReducer";
import metricReducer from "./metricReducer";
// import filterReducer from "./filterReducer";
import mateReducer from "./mateReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  properties: propertyReducer,
  metric: metricReducer,
  // filters: filterReducer,
  mates: mateReducer,
});

export default rootReducer;
