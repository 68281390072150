import { Link } from "react-router-dom";
import AppComponent from "../components/layouts/AppComponent";
import PAGES_URL from "../routes";
import {
  Bs1CircleFill,
  Bs2CircleFill,
  Bs3CircleFill,
  Bs4CircleFill,
  Bs5CircleFill,
  BsBuilding,
  BsPerson,
} from "react-icons/bs";
import { GrAd, GrGroup, GrHpeLabs } from "react-icons/gr";

import SignUp from "../assets/img/screenshot/signup.png";
import GetStarted from "../assets/img/screenshot/get-started.png";
import MetricsSearch from "../assets/img/screenshot/metrics-search.png";
import MetricsProperty from "../assets/img/screenshot/property.png";
import MetricsImportance from "../assets/img/screenshot/importance.png";
import Results from "../assets/img/screenshot/results.png";
import Filters from "../assets/img/screenshot/filters3.png";
import MAtes from "../assets/img/screenshot/mates.png";

const HowItWorks = () => {
  return (
    <AppComponent>
      <section className="container  pt-3 pb-lg-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb pt-3">
            <li className="breadcrumb-item">
              <Link to={PAGES_URL.Landing}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link className="active" to="#">
                How It Works
              </Link>
            </li>
          </ol>
        </nav>
        <div className="row mt-4 justify-content-center mb-5">
          <div className="col-lg-7 text-center">
            <h3 className=" display-5 fw-normal">A Guide To Metricise</h3>
            <p className="fw-normal">
              Our advanced search algorithm needs a bit more insight into your
              property preferences. <br /> This helps it tailor results to your
              tastes and save you valuable time. <br />
              To make the most of this innovative search experience, here's a
              guide to Metricise.
            </p>
          </div>
        </div>
        <div className="row pt-4">
          <aside className="col-md-3 d-md-block d-none position-relative pe-lg-2">
            <nav className="border-end sticky-top" style={{ top: "216px" }}>
              <ul className="nav flex-column fs-sm">
                <li className="nav-item mt-3 mb-4">
                  <a
                    className="nav-link py-1 px-4 fw-normal  d-flex align-items-center "
                    href={"#sign-up"}
                    data-scroll
                  >
                    <Bs1CircleFill size={15} className="me-2" />
                    Sign Up
                  </a>
                </li>
                <li className="nav-item mb-4">
                  <a
                    className="nav-link py-1 px-4 fw-normal d-flex align-items-center"
                    href={"#get-started"}
                    data-scroll
                  >
                    <Bs2CircleFill size={15} className="me-2" />
                    Get Started
                  </a>
                </li>
                <li className="nav-item mb-4">
                  <a
                    className="nav-link py-1 px-4 fw-normal  d-flex align-items-center"
                    href={"#metrics"}
                    data-scroll
                  >
                    <Bs3CircleFill size={15} className="me-2" />
                    Creating Your Metric Base
                  </a>
                </li>
                <li className="nav-item mb-4">
                  <a
                    className="nav-link py-1 px-4 fw-normal d-flex align-items-center"
                    href={"#property"}
                    data-scroll
                  >
                    <Bs4CircleFill size={15} className="me-2" />
                    Properties
                  </a>
                </li>
                <li className="nav-item mb-4">
                  <a
                    className="nav-link py-1 px-4 fw-normal d-flex align-items-center"
                    href={"#mates"}
                    data-scroll
                  >
                    <Bs5CircleFill size={15} className="me-2" />
                    MetricMates
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <div className="col-md-7">
            <div className="mb-5 pb-3">
              <div className="d-flex align-items-start">
                <GrGroup
                  size={40}
                  className="mt-2 text-primary me-lg-2"
                  style={{ width: "100px" }}
                />
                <div id="sign-up">
                  <h2
                    className="mb-4 display-5 fw-normal"
                    style={{ fontSize: "35px" }}
                  >
                    Create Your Account
                  </h2>
                  <p className="fw-normal">
                    First, sign up for a Metricise account to seamlessly track
                    your favourite metrics, properties, profiles and manage your
                    investment options.
                  </p>
                  <img
                    src={SignUp}
                    style={{ width: "300px" }}
                    className="my-5"
                    alt="signup"
                  />
                </div>
              </div>
            </div>
            <div className="pb-md-5 pb-3 mt-5 pt-5">
              <div className="d-flex align-items-start">
                <GrAd
                  size={50}
                  className="mt-2 text-primary me-lg-3"
                  style={{ width: "150px" }}
                />
                <div id="get-started">
                  <h2
                    className="mb-4 display-5 fw-normal"
                    style={{ fontSize: "35px" }}
                  >
                    Get Started
                  </h2>
                  <p className="fw-normal">
                    Go to the home page and click on <b>Get Started</b>. This
                    will redirect you to our search wizard, where you begin
                    creating your Metric Base. Your Metric Base is what we use
                    to rank all the properties on our site. It includes all the
                    metrics you value. Let's get started on selecting those.
                  </p>
                  <img
                    src={GetStarted}
                    style={{ width: "300px" }}
                    className="my-5"
                    alt="signup"
                  />
                </div>
              </div>
            </div>
            <div className="pb-md-5 pb-3 mt-5 pt-5">
              <div className="d-flex align-items-start">
                <GrHpeLabs
                  size={40}
                  className="mt-2 text-primary me-lg-2"
                  style={{ width: "100px" }}
                />
                <div id="metrics">
                  <h2
                    className=" display-5 fw-normal"
                    style={{ fontSize: "35px" }}
                  >
                    Create Your Metric Base
                  </h2>
                  <p className="fw-normal">
                    Each house has lots of different metrics, such as price,
                    bedrooms, how close it is to a station, its yields, and so
                    on.
                  </p>
                  <p className="fw-normal">
                    For each value, we each have our own preference of what end
                    of the spectrum we prefer.
                  </p>
                  <p className="fw-normal">
                    For example, let's take the population metric. Some people
                    might be looking for areas of the lowest population, and
                    others might be looking for the highest. Our wizard simply
                    prompts you to select your desired end of the spectrum.
                  </p>
                  <h2
                    className=" display-5 fw-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Search
                  </h2>
                  <p className="fw-normal">
                    The first step is to decide whether you are using our search
                    tool to rent or buy.
                  </p>
                  <p className="fw-normal">
                    You may also filter the property search to a specific number
                    of bedrooms and bathrooms.
                  </p>
                  <img
                    src={MetricsSearch}
                    style={{ width: "300px" }}
                    className="mb-5"
                    alt="signup"
                  />
                  <p className="fw-normal">
                    Note - unless you're renting, we don't recommend this! If
                    you want a house with loads of bedrooms, then we can sort
                    that out on the next page. Try not to filter where you can!
                  </p>
                  <h2
                    className=" display-5 fw-bold mt-5"
                    style={{ fontSize: "20px" }}
                  >
                    Setting Your Preferences
                  </h2>
                  <p className="fw-normal">This is where the magic happens!</p>
                  <p className="fw-normal">
                    You'll now be faced with metrics to do with the property
                    itself. All you have to do is select your preferred end of
                    the spectrum for the metrics you care about.
                  </p>
                  <p className="fw-normal">
                    For example, I'd ideally be looking for the biggest house at
                    the cheapest price. As such, I'd select "Lowest" for the
                    price and "Most" for the square footage.
                  </p>
                  <p className="fw-normal">
                    If you are indifferent about a metric, or it's not crucial
                    to your search, just leave it blank!{" "}
                    <b className="text-dark">
                      You do not need to set a preference for all the metrics.
                    </b>
                  </p>

                  <img
                    src={MetricsProperty}
                    style={{ width: "300px" }}
                    className="mb-5"
                    alt="signup"
                  />
                  <p className="fw-normal">
                    Now, just do the exact same for the area metrics (and the
                    investment metrics if you are an investor).
                  </p>
                  <h2
                    className=" display-5 fw-bold mt-5"
                    style={{ fontSize: "20px" }}
                  >
                    Setting Your Importances
                  </h2>
                  <p className="fw-normal">
                    Finally, we come to the "Importances" page. You should now
                    see your selected metrics in a list.
                  </p>
                  <p className="fw-normal">
                    (Ideally, there should be around 4 or 6 metrics here. Much
                    less, and you're missing out on our search power! Much more,
                    and the chances of a well-matched property is very slim.)
                  </p>
                  <img
                    src={MetricsImportance}
                    style={{ width: "300px" }}
                    className="mb-5"
                    alt="signup"
                  />
                  <p className="fw-normal">
                    Now rearrange all your options in order of importance.
                  </p>
                  <p className="fw-normal">
                    For example, if a low crime rate is most important to you,
                    you can drag and drop it to the top of the list.
                  </p>
                  <p className="fw-normal">
                    And we're done! Your Metric Base is ready!
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 pb-3">
              <div className="d-flex align-items-start">
                <BsBuilding
                  size={40}
                  className="mt-2 text-primary me-lg-2"
                  style={{ width: "100px" }}
                />
                <div id="property">
                  <h2
                    className="mb-4 display-5 fw-normal"
                    style={{ fontSize: "35px" }}
                  >
                    Properties
                  </h2>
                  <p className="fw-normal">So what's happened?</p>
                  <p className="fw-normal">
                    What's happened is that we've taken your Metric Base as a
                    guide of what you're looking for and scored all the
                    properties on our site, according to your rankings.
                  </p>
                  <img
                    src={Results}
                    style={{ width: "300px" }}
                    className="my-5"
                    alt="signup"
                  />
                  <p className="fw-normal">
                    No need to scroll endlessly anymore. Rest assured that your
                    most important metrics were given priority and that the most
                    well-matched properties are at the top of your page!
                  </p>
                  <h2
                    className=" display-5 fw-bold"
                    style={{ fontSize: "20px" }}
                  >
                    Filtering
                  </h2>
                  <p className="fw-normal">
                    Now's the time to filter. Why now?
                  </p>
                  <p className="fw-normal">
                    At Metricise, we believe that a holistic search might bring
                    up properties that we didn't realise we valued. Filtering
                    those out early on might mean missing out on huge
                    opportunities.
                  </p>
                  <img
                    src={Filters}
                    style={{ width: "300px" }}
                    className="my-5"
                    alt="signup"
                  />
                  <p className="fw-normal">
                    Now that everything's been ranked, we can now make the most
                    of Metricise's 40+ filters.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 pb-3">
              <div className="d-flex align-items-start">
                <BsPerson
                  size={40}
                  className="mt-2 text-primary "
                  style={{ width: "100px" }}
                />
                <div id="mates">
                  <h2
                    className="mb-4 display-5 fw-normal"
                    style={{ fontSize: "35px" }}
                  >
                    MetricMates
                  </h2>
                  <p className="fw-normal">
                    Our investors can then head over to the MetricMates page to
                    see other investors who value similar metrics to themselves.
                  </p>
                  <p className="fw-normal">
                    Network with them, have a look at the other's top-ranked
                    properties, and discuss opportunities for co-investment!
                  </p>
                  <img
                    src={MAtes}
                    style={{ width: "300px" }}
                    className="my-5"
                    alt="signup"
                  />

                  <p className="fw-normal">
                    Now you know everything you need to know.{" "}
                  </p>
                  <Link className="btn btn-primary" to={PAGES_URL.Metric}>
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-5 pb-lg-5">
        <div className="py-md-4 py-5 bg-secondary rounded-3">
          <div className="col-sm-10 col-11 d-flex flex-md-row flex-column align-items-center justify-content-between mx-auto px-0">
            <div
              className="order-md-1 order-2 text-md-start text-center"
              style={{ maxWidth: "524px" }}
            >
              <h2 className="mb-4 pb-md-3">
                Haven't found the answer? <br /> We can help.
              </h2>
              <Link
                className="btn btn-lg btn-primary rounded-pill w-sm-auto w-100"
                to={PAGES_URL.ContactUs}
              >
                Contact us
              </Link>
            </div>
            <img
              className="order-md-2 order-1 ms-md-4"
              style={{ width: "400px" }}
              src={
                "https://cdni.iconscout.com/illustration/premium/thumb/mail-notification-illustration-download-in-svg-png-gif-file-formats--letter-form-email-phone-network-communication-illustrations-2139454.png?f=webp"
              }
              alt="Illustration"
            />
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

export default HowItWorks;
