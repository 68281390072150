import {
  CLEAR_PROPERTY_FILTERS,
  SET_PROPERTY_FILTERS,
} from "../reducers/types";
import supabase from "../../lib/supabase/client";

export const setFilteredData = (data: any) => async (dispatch: any) => {
  dispatch({ type: SET_PROPERTY_FILTERS, payload: data });
};

export const clearFilteredData = () => async (dispatch: any) => {
  dispatch({ type: CLEAR_PROPERTY_FILTERS });
};

export const getTubStationDistance = async (value: any) => {
  let { data } = await supabase.from("point_of_interests").select(
    "latitude,longitude,line",
  ).eq(
    "category",
    "Tube Station",
  ).eq("line", value);
  return data;
};
