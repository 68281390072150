import React from "react";
import AppComponent from "../components/layouts/AppComponent";
import HomeBanner from "../assets/img/home-banner.jpeg";
import Work01 from "../assets/img/work01.png";
import Work02 from "../assets/img/work02.png";
import Work03 from "../assets/img/work03.png";
import { Link } from "react-router-dom";
import Recent01 from "../assets/img/recent-house-1.jpeg";
import PAGES_URL from "../routes";

const Landing = () => {
  return (
    <AppComponent>
      <section
        className="bg-position-top-center bg-repeat-0 pt-5 home-banner"
        style={{
          backgroundImage: `url(${HomeBanner})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container pt-5">
          <div className="row pt-lg-4 pt-xl-5">
            <div className="col-lg-5 col-md-5 pt-3 pt-md-4 pt-lg-5">
              <h1 className="display-4 text-light pb-2 mb-4 me-md-n5">
                Find the ideal property, whatever your needs
              </h1>
              <p className="fs-lg text-light opacity-70">
                Create a refined search from the most expansive property
                database in the U.K.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4 mt-sm-3 mt-lg- pb-5 mb-md-4">
          <p className="text-white mb-2">
            First time here? Check our guide below.
            <br />
          </p>
          <Link to={PAGES_URL.HowItWorks} className=" btn btn-light">
            Guide
          </Link>
          <Link to={PAGES_URL.Metric} className="ms-3 btn btn-primary">
            Get Started
          </Link>
        </div>
      </section>
      <div className="container mt-5 mt-lg-0">
        <iframe
          width={"100%"}
          height={600}
          src="https://youtube.com/embed/DteUeH-Xkqw"
          title="video"
        ></iframe>
      </div>
      <section className="container mb-5 mt-n3 mt-lg-0 section-lg">
        <div className=" mx-n2">
          <div
            className="row gx-4 mx-0 py-3"
            data-carousel-options='{"items": 3, "controls": false, "responsive": {"0":{"items":1},"500":{"items":2},"768":{"items":3}}}'
          >
            <div className="col-lg-4">
              <div className="  border-0 h-100 pb-2 pb-sm-3 px-sm-3 text-center">
                <img
                  className="d-block mx-auto my-3"
                  src={Work01}
                  width="256"
                  alt="Illustration"
                />
                <div className="card-body">
                  <h2 className="h4 card-title">Multi-Metric Searches</h2>
                  <p className="mt-3 text-dark">
                    Tailor your search to your exact needs and priorities for a
                    faster, more relevant home hunt.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="  border-0 h-100 pb-2 pb-sm-3 px-sm-3 text-center">
                <img
                  className="d-block mx-auto my-3"
                  src={Work02}
                  width="256"
                  alt="Illustration"
                />
                <div className="card-body">
                  <h2 className="h4 card-title">
                    Find Like-Minded Co-Investors
                  </h2>
                  <p className="mt-3 text-dark">
                    Get on the property ladder faster with less capital and
                    amplify your buying power by collaborating with like-minded
                    investors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="  border-0 h-100 pb-2 pb-sm-3 px-sm-3 text-center">
                <img
                  className="d-block mx-auto my-3"
                  src={Work03}
                  width="256"
                  alt="Illustration"
                />
                <div className="card-body">
                  <h2 className="h4 card-title">Brand New Filters</h2>
                  <p className="mt-3 text-dark">
                    Refine your property search with precision using innovative
                    filters like estimated rental yield, green area proximity,
                    area growth rate, and tube line proximity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-5 pb-xl-5 pb-md-2">
        <h2 className="h3 mb-3 text-center">Hear from our customers</h2>
        <div className="tns-carousel-wrapper tns-controls-outside-lg  col-lg-10 mx-auto px-0">
          <div className="tns-carousel-inner">
            <div className="d-flex flex-md-row flex-column align-items-md-start mx-3 py-3">
              <img
                className="d-md-block d-none me-4 rounded-3"
                src={Recent01}
                width="306"
                alt="Customer 2"
              />
              <div className="card border-0 shadow-sm h-100">
                <blockquote className="blockquote card-body">
                  <p>
                    Metricise really made our property search a breeze. My
                    friend and I had been going in circles for months, trying to
                    find a place that worked for both of us. With Metricise, we
                    just plugged in what we each wanted, and the platform did
                    the rest—ranking the best options that suited both our
                    needs. We found the perfect investment property so quickly!
                    It honestly saved us so much time and hassle!
                  </p>
                  <footer className="d-flex align-items-center">
                    {/* <img src={Recent01} width="56" alt="Logo" /> */}
                    <div className="ps-0">
                      <h6 className="fs-base mb-0">Co-Investment Success</h6>
                      <div className="text-muted fw-normal fs-sm">
                        Sarah and Michael, Co-Investors
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="d-flex align-items-start mx-3 py-3">
              <img
                className="d-md-block d-none me-4 rounded-3"
                src={Recent01}
                width="306"
                alt="Customer "
              />
              <div className="card border-0 shadow-sm h-100">
                <blockquote className="blockquote card-body">
                  <p>
                    I had trouble finding a rental that matched my budget and
                    lifestyle until I used Metricise. Their platform let me
                    filter by factors like commute, amenities, and rent, then
                    ranked the best options for me. Within minutes, I found a
                    place that suited my needs perfectly. The process was simple
                    and stress-free!
                  </p>
                  <footer className="d-flex align-items-center">
                    {/* <img src={Recent01} width="56" alt="Logo" /> */}
                    <div className="ps-0">
                      <h6 className="fs-base mb-0">Renting Made Simple.</h6>
                      <div className="text-muted fw-normal fs-sm">
                        Alex, Renter
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <RecentProperty /> */}
    </AppComponent>
  );
};

export default Landing;
