import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AppComponent from "../../components/layouts/AppComponent";
import Avatar from "../../assets/img/avatar.png";
import { fetchMatchedMates } from "../../redux/actions/mates.action";
import { useEffect, useState } from "react";
import PAGES_URL from "../../routes";
import { currencyFormat, splitString } from "../../helpers/String";
import MetricsMateSkeleton from "../../components/skeleton/MetricsMate";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { matchedMates } from "../../helpers/Mates";
import { ImPieChart } from "react-icons/im";

type Props = {
  fetch: Function;
};

const MatchedMates = ({ fetch }: Props) => {
  const [matched, setMatched] = useState<any>(null);

  useEffect(() => {
    fetch().then((matched: any) => {
      matchedMates(matched).then((records: any) => setMatched(records));
    });
  }, [fetch]);

  return (
    <AppComponent dark>
      <LoaderComponent
        topStyle={"100px"}
        progress={matched?.length > 0 ? 100 : 25}
        loading={matched?.length > 0 ? false : true}
      />
      {matched ? (
        <div className="container content-overlay mt-3 mb-md-4 ">
          <nav className="mb-3 mb-md-4 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb breadcrumb-dark">
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.Landing}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to={"#"}>Find My MetricMates</Link>
              </li>
            </ol>
          </nav>
          <div className="bg-light  rounded-3 mb-2">
            <div className="d-flex align-items-center justify-content-between py-4 mt-3 mb-2">
              <div>
                <h1 className="h3 mb-2">Find My MetricMates</h1>
                <p className="fw-bold text-dark">
                  Your recommended network of co-investors
                </p>
              </div>
              {/* <Link className="fw-bold text-decoration-none" to="#">
              <i className="fi-x fs-xs me-2"></i>
              <span className="align-middle">Clear all</span>
            </Link> */}
            </div>
            <div className="row g-2 g-md-4">
              {matched &&
                matched.map((el: any, index: any) => (
                  <div className="col-md-6 col-lg-4" key={index}>
                    <div className="card bg-secondary card-hover h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                          <img
                            className="rounded-circle me-3"
                            src={el?.avatar || Avatar}
                            width="48"
                            alt={el?.fullname}
                          />
                          <div className="fs-sm text-dark opacity-80 px-1">
                            <span className="d-block fs-sm fw-bold">
                              {el.fullname}
                            </span>
                            {el.investments?.risk_tolerance && (
                              <span className="fs-sm fw-normal me-2 ">
                                <div className="badge bg-primary text-light fw-bold ">
                                  {el.investments.risk_tolerance}
                                </div>
                              </span>
                            )}
                            {el.investments?.usable_bankroll && (
                              <span className="fs-sm fw-normal me-2">
                                <div className="badge bg-default text-dark border border-primary fw-bold">
                                  {currencyFormat(
                                    el.investments?.usable_bankroll
                                  )}
                                </div>
                              </span>
                            )}
                            {el.investments?.preferred_share && (
                              <span className="fs-sm fw-normal ">
                                <ImPieChart className="text-primary" />{" "}
                                {el.investments.preferred_share}
                              </span>
                            )}
                          </div>
                          {el.investments?.years_of_experience && (
                            <span className="badge bg-faded-primary rounded-pill p-3  fs-sm ms-auto">
                              <b>{el.investments?.years_of_experience}</b>
                            </span>
                          )}
                        </div>
                        <Link
                          className="text-nav stretched-link text-decoration-none fw-normal"
                          to={`${PAGES_URL.SingleMates}/${el.id}`}
                        ></Link>
                        {/* <h3 className="h6 card-title mb-2">
                          <Link
                            className="text-nav stretched-link text-decoration-none fw-normal"
                            to={`${PAGES_URL.SingleMates}/${el.id}`}
                          >
                            Open To Network
                          </Link>
                        </h3> */}

                        <p className="fs-sm mb-0 fw-normal">
                          {el.bio ? (
                            el.bio
                          ) : (
                            <i className="text-m">
                              {el.fullname} does not have a personal biography
                              set yet
                            </i>
                          )}
                        </p>
                      </div>

                      <div className="card-footer ">
                        <div className="">
                          <div className="d-flex">
                            {el?.metrics &&
                              el.metrics
                                ?.slice(0, 3)
                                ?.map((lt: any, index: any) => (
                                  <div className="me-2">
                                    <span
                                      style={{ fontSize: ".8em !important" }}
                                      className="badge bg-faded-dark fw-normal fs-xs  m-0"
                                    >
                                      {splitString(lt.name)}
                                    </span>
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <MetricsMateSkeleton />
      )}
    </AppComponent>
  );
};
const MapStateToProps = (state: any) => ({
  selected: state.metric.selectedMetrics,
});

const MapDispatchToProps = (dispatch: any) => ({
  fetch: async () => dispatch(fetchMatchedMates()),
});
export default connect(MapStateToProps, MapDispatchToProps)(MatchedMates);
