import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SingleMetricsMateSkeleton = () => {
  return (
    <div className="container mt-3 mb-md-4">
      <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Skeleton width={50} />
          </li>
          <li className="breadcrumb-item">
            <Skeleton width={50} />
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Skeleton width={150} />
          </li>
        </ol>
      </nav>
      <div className="row">
        <aside className="col-lg-4 col-md-4 mb-5">
          <div className="pe-lg-3">
            <Skeleton
              circle={true}
              containerClassName="d-block rounded-circle mx-auto mx-md-0 mb-3"
              width={100}
              height={100}
            />

            <h2 className="h4 text-center text-md-start mb-0">
              <Skeleton width={150} />
            </h2>
            <p className="text-center text-md-start fs-sm fw-normal mb-0 pb-1">
              <Skeleton width={100} />
            </p>
            <div className="d-flex justify-content-center justify-content-md-start border-bottom pb-4 mb-4">
              <span className="star-rating">
                <Skeleton width={100} />
              </span>
              <div className="text-muted ms-2">
                <Skeleton width={50} />
              </div>
            </div>
            <ul className="d-table list-unstyled mx-md-0 mb-3 mb-md-4">
              <li className="mb-2">
                <Skeleton width={150} />
              </li>
              <li>
                <Skeleton width={150} />
              </li>
            </ul>
            <div className="border-bottom pb-4 mb-4">
              <p className="fs-sm mb-0 fw-normal text-dark">
                <p className="text-dark mb-1">
                  {" "}
                  <Skeleton width={100} />
                </p>
                <Skeleton count={5} />
              </p>
            </div>
          </div>
        </aside>
        <div className="col-lg-6 col-md-6">
          <div className="row">
            <p className="text-dark mb-1">
              {" "}
              <Skeleton width={150} />
            </p>
            <div className="mb-4 pb-md-3">
              <Skeleton height={70} />
            </div>

            <p className="text-dark mb-1">
              {" "}
              <Skeleton width={150} />
            </p>
            <div className="mb-4 pb-md-3">
              <Skeleton height={70} />
            </div>

            <p className="text-dark mb-1">
              {" "}
              <Skeleton width={150} />
            </p>
            <div className="mb-4 pb-md-3">
              <ul className="list-unstyled row text-dark  gy-1 mb-1 text-nowrap">
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
                <li className="d-block">
                  <Skeleton width={300} />
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            {<Skeleton />}
            {Array(6)
              .fill("Dummy")
              .map((el: any, key: any) => (
                <div className="col-lg-4 pb-2" key={key}>
                  <div className="position-relative">
                    <div className="position-relative mb-3">
                      <Skeleton width={200} height={100} />
                    </div>
                    <h3 className="mb-2 fs-lg">
                      <Skeleton />
                    </h3>
                    <div className="card-footer d-flex align-items-center text-nowrap">
                      <ul className="list-unstyled d-flex fs-sm">
                        <li className="fs-sm">
                          <Skeleton width={60} />
                        </li>
                        <li className="ms-3">
                          <Skeleton width={60} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-lg-2">
          <p className="text-dark mb-1 ms-3">
            {" "}
            <Skeleton />
          </p>

          <ul className="list-unstyled ms-3">
            {Array(4)
              .fill("Dummy")
              .map((el: any, index: any) => (
                <Skeleton />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SingleMetricsMateSkeleton;
