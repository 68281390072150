import {
  SET_METRIC_PAGE,
  SET_METRIC_SEARCH,
  SET_SELECTED_METRICS,
} from "../reducers/types";
import supabase from "../../lib/supabase/client";
import { getUserId } from "./user.action";

export const setSearch = (data: any) => (dispatch: any) => {
  dispatch({ type: SET_METRIC_SEARCH, payload: data });
};

export const setMetricPage = (data: any) => (dispatch: any) => {
  dispatch({ type: SET_METRIC_PAGE, payload: data });
};

export const setSelectedMetrics = (data: any) => (dispatch: any) => {
  dispatch({ type: SET_SELECTED_METRICS, payload: data });
};

export const createUserDefinedMetrics =
  (formData: any, search: any, user_id: any) => async (dispatch: any) => {
    let arr = formData.map((el: any) => {
      el.user_id = user_id;
      el.type = search.type;
      return el;
    });
    console.log(arr);
    try {
      const { data: metrics } = await supabase.from("metrics").upsert(
        arr,
        { onConflict: "user_id, name" },
      ).select("*");

      let ids: any = metrics?.map((el: any) => el.id);
      let arrToString: any = ids.toString();

      await supabase
        .from("metrics").delete().not(
          "id",
          "in",
          `(${arrToString})`,
        ).eq("user_id", user_id);
    } catch (error: any) {
    }
  };

export const getActiveUserMetrics = async () => {
  const id: any = await getUserId();
  const { data } = await supabase.from("metrics").select("*").eq("user_id", id);
  return data;
};
