import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "../assets/img/logo-light.png";
import PAGES_URL from "../routes";
import { connect } from "react-redux";

const Footer = ({ user }: any) => {
  return (
    <footer className="footer bg-dark">
      <div className="border-bottom border-light py-4">
        <div className="container d-sm-flex align-items-center justify-content-between">
          <Link className="d-inline-block" to={PAGES_URL.Landing}>
            <img src={LogoLight} width="100px" alt="logo" />
          </Link>
          <div className="d-flex pt-3 pt-sm-0">
            <div className="dropdown ms-n3">
              <button
                className="btn btn-light btn-link btn-sm dropdown-toggle fw-normal py-2"
                type="button"
                data-bs-toggle="dropdown"
              >
                <i className="fi-globe me-2"></i>English
              </button>
              <div className="dropdown-menu dropdown-menu-dark w-100">
                <Link className="dropdown-item" to="#">
                  Deutsch
                </Link>
                <Link className="dropdown-item" to="#">
                  Français
                </Link>
                <Link className="dropdown-item" to="#">
                  Español
                </Link>
              </div>
            </div>
            <div className="dropdown">
              <button
                className="btn btn-light btn-link btn-sm dropdown-toggle fw-normal py-2 pe-2"
                type="button"
                data-bs-toggle="dropdown"
              >
                <i className="fi-map-pin me-2"></i>United Kingdom
              </button>
              <div
                className="dropdown-menu dropdown-menu-dark dropdown-menu-sm-end"
                style={{ minWidth: "7.5rem" }}
              >
                <Link className="dropdown-item" to="#">
                  Chicago
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pb-3 pt-lg-5 pb-lg-4">
        <div className="row pt-2 pt-lg-0">
          <div className="col-lg-3 pb-2 mb-4">
            <h3 className="h5 text-light mb-2">Subscribe to our newsletter</h3>
            <p className="fs-sm text-light opacity-70">
              Don’t miss any relevant offers!
            </p>
            <form className="form-group form-group-light w-100">
              <div className="input-group input-group-sm">
                <span className="input-group-text">
                  {" "}
                  <i className="fi-mail"></i>
                </span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your email"
                />
              </div>
              <button className="btn btn-primary btn-icon btn-sm" type="button">
                <i className="fi-send"></i>
              </button>
            </form>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 offset-xl-1 mb-2 mb-sm-4">
            <h3 className="fs-base text-light">Buying &amp; Renting</h3>
            <ul className="list-unstyled fs-sm">
              <li>
                <Link className="nav-link-light" to={PAGES_URL.Metric}>
                  Find a house
                </Link>
              </li>
              <li>
                <Link className="nav-link-light" to={PAGES_URL.Property}>
                  Property
                </Link>
              </li>
              <li>
                <Link className="nav-link-light" to={PAGES_URL.MatchedMates}>
                  My Metric Mates
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-2 mb-sm-4">
            <h3 className="fs-base text-light">About</h3>
            <ul className="list-unstyled fs-sm">
              <li>
                <Link className="nav-link-light" to={PAGES_URL.Landing}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-link-light" to={PAGES_URL.ContactUs}>
                  Contact us
                </Link>
              </li>
              <li>
                <Link className="nav-link-light" to={PAGES_URL.HowItWorks}>
                  How it works
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-2 mb-sm-4">
            <h3 className="fs-base text-light">Profile</h3>
            <ul className="list-unstyled fs-sm">
              <li>
                <Link className="nav-link-light" to={PAGES_URL.Profile}>
                  My account
                </Link>
              </li>
              {user ? (
                <>
                  <li>
                    <Link
                      className="nav-link-light"
                      to={PAGES_URL.ProfilePreference}
                    >
                      Investment Preference
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link-light"
                      to={PAGES_URL.Subscription}
                    >
                      My Subscription
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link-light"
                      to={PAGES_URL.PasswordUpdate}
                    >
                      Security
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link className="nav-link-light" to={PAGES_URL.Login}>
                      Sign in
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link-light" to={PAGES_URL.SignUp}>
                      Create an account
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="col-xl-2 col-lg-3 col-sm-6 col-md-3 mb-2 mb-sm-4">
            {/* <Link
              className="d-flex align-items-center text-decoration-none mb-2"
              to="tel:4065550120"
            >
              <i className="fi-device-mobile me-2"></i>
              <span className="text-light">(406) 555-0120</span>
            </Link> */}
            {/* <Link
              className="d-flex align-items-center text-decoration-none mb-2"
              to="mailto:hello@metricise.co.uk"
            >
              <i className="fi-mail me-2"></i>
              <span className="text-light">hello@metricise.co.uk</span>
            </Link> */}
            <Link
              className="d-flex align-items-center text-decoration-none mb-2"
              to="mailto:aria@metricise.co.uk"
            >
              <i className="fi-mail me-2"></i>
              <span className="text-light">aria@metricise.co.uk</span>
            </Link>
            <div className="d-flex flex-wrap pt-4">
              <Link
                className="btn btn-icon btn-translucent-light btn-xs rounded-circle mb-2 me-2"
                to="#"
              >
                <i className="fi-facebook"></i>
              </Link>
              <Link
                className="btn btn-icon btn-translucent-light btn-xs rounded-circle mb-2 me-2"
                to="#"
              >
                <i className="fi-twitter"></i>
              </Link>
              <Link
                className="btn btn-icon btn-translucent-light btn-xs rounded-circle mb-2 me-2"
                to="#"
              >
                <i className="fi-telegram"></i>
              </Link>
              <Link
                className="btn btn-icon btn-translucent-light btn-xs rounded-circle mb-2"
                to="#"
              >
                <i className="fi-messenger"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-lg-flex align-items-center justify-content-between fs-sm pb-3">
        {/* <div className="d-flex flex-wrap justify-content-center order-lg-2 mb-3">
          <Link className="nav-link nav-link-light fw-normal" to="#">
            Terms of use
          </Link>
          <Link className="nav-link nav-link-light fw-normal" to="#">
            Privacy policy
          </Link>
          <Link className="nav-link nav-link-light fw-normal" to="#">
            Accessibility statement
          </Link>
          <Link className="nav-link nav-link-light fw-normal pe-0" to="#">
            Interest based ads
          </Link>
        </div> */}
        <p className="text-center text-lg-start order-lg-1 mb-lg-0">
          <span className="text-light opacity-50">
            &copy; All rights reserved. Made by{" "}
          </span>
          <Link
            className="nav-link-light fw-bold"
            to={PAGES_URL.Landing}
            target="_blank"
            rel="noopener"
          >
            Metricise
          </Link>
        </p>
      </div>
    </footer>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Footer);
