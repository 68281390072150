import React, { useEffect, useState } from "react";
import Menu from "./components/Menu";
import Breadcrumbs from "./components/Breadcrumbs";
import AppComponent from "../../components/layouts/AppComponent";
import { connect } from "react-redux";
import { getActiveUserMetrics } from "../../redux/actions/metric.action";
import PAGES_URL from "../../routes";
import { Link } from "react-router-dom";
import { splitString } from "../../helpers/String";

const ProfilePreference = ({ fetch }: any) => {
  const [state, setState] = useState<any>(null);
  useEffect(() => {
    fetch().then((metrics: any) => {
      setState(metrics);
    });
  }, [fetch]);

  console.log(state);
  return (
    <AppComponent dark>
      <section className="section mt-3">
        <div className="container pb-lg-4 mb-sm-2">
          <Breadcrumbs name="Preference" />
          <div className="row">
            <Menu />
            <div className="col-lg-8 col-md-7 mb-5">
              <h1 className="h2 mb-1">My Metric Base</h1>
              <p className="fw-normal mb-4">
                <span>
                  {" "}
                  When looking for a property, below are the metrics I care
                  about, in order of importance.
                </span>{" "}
                <br />
                Click <Link to={PAGES_URL.Metric}>Edit</Link> to update your
                Metric Base.
              </p>
              <div className="d-flex flex-column ">
                {state
                  ? state?.map((el: any) => (
                      <div className="col">
                        <div className="btn-group btn-group-lg mb-3">
                          <button
                            style={{ width: "300px" }}
                            className="btn btn-secondary fw-normal rounded-pill rounded-end-0 ps-0"
                            type="button"
                          >
                            {el.label} {splitString(el.name)}
                          </button>
                          <div
                            className="position- border-start border-light zindex-5"
                            style={{ marginLeft: "-1px;" }}
                          ></div>
                          {/* <button
                            className="btn btn-primary rounded-pill rounded-start-0 px-3"
                            type="button"
                          >
                            <i className="fi-x me-1"></i>
                          </button> */}
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetch: async () => getActiveUserMetrics(),
});

export default connect(null, mapDispatchToProps)(ProfilePreference);
