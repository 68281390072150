import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthComponent from "../../components/layouts/AuthComponent";
import PAGES_URL from "../../routes";
import { InputField, InputPasswordField } from "../../components/forms/Inputs";
import { LoginValidator } from "../../lib/validators/auth.validator";
import { loginAction } from "../../redux/actions/auth.action";
import Button from "../../components/forms/Buttons";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { toast } from "react-toastify";

type Props = {
  email: string;
  password: string;
};

const Login = ({ login, isAuthenticated, user, metrics }: any) => {
  const User = { email: "", password: "" };
  const [state, setState] = useState<Props>(User);
  const [error, setError] = useState<Props>(User);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  let newData: any = state;
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate(PAGES_URL.Property);
    }
  });

  /**
   * submit form action
   */
  const handleSubmit = (e: any) => {
    setLoading(true);
    setProgress(25);
    const errors = LoginValidator(state);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      setLoading(false);
      return;
    }
    if (metrics.selectedMetrics?.length > 0) {
      const { search, selectedMetrics } = metrics;

      newData = { state, search, selectedMetrics };
      setState(newData);
    }
    login(newData).then((data: any) => {
      setProgress(75);
      if (data.__isAuthError) {
        setLoading(false);
        setProgress(100);
        toast.error("Email or Password is incorrect!");
      } else {
        toast.success("Successfully Signed In");
      }
    });
  };

  return (
    <>
      <AuthComponent>
        <LoaderComponent progress={progress} loading={loading} />
        <div className="mt-auto"></div>
        <h1 className="h2">Sign In to Metricise</h1>
        <div className="nav fs-sm mb-3 mb-lg-4">
          I do not have an account
          <Link
            className="nav-link text-decoration-underline p-0 ms-2"
            to={PAGES_URL.SignUp}
          >
            Sign up
          </Link>
        </div>

        <form className="needs-validation">
          <div className="position-relative mb-4">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <InputField
              onChange={handleInputChange}
              name="email"
              value={state.email}
              errorMessage={error.email}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <InputPasswordField
              onChange={handleInputChange}
              name="password"
              value={state.password}
              errorMessage={error.password}
            />
          </div>
          <div className="d-flex flex-column gap-2 mb-4">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="privacy"
                required
              />
              <label htmlFor="privacy" className="form-check-label">
                Remember me{" "}
              </label>
            </div>
          </div>
          <Button
            loading={loading}
            className="btn-primary btn-lg w-100"
            onClick={handleSubmit}
          >
            Sign in
          </Button>
        </form>
      </AuthComponent>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  errorMessage: state.auth.error,
  responseMessage: state.auth.response,
  metrics: state.metric,
});

const mapDispatchToProps = (dispatch: any) => ({
  login: async (data: any) => dispatch(loginAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
