/** @format */

import { configureStore, Tuple } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
// import { composeWithDevTools } from "@redux-devtools/extension";
import { loadState } from "./localStorage";
import rootReducer from "./reducers/rootReducer";
import sessionExpirationMiddleware from "./middlewares/auth.middleware";

const LoggerMiddleware = createLogger();
const persistedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: () =>
    new Tuple(LoggerMiddleware, thunk, sessionExpirationMiddleware),
  devTools: true,
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
