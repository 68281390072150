type LoginValidatorProps = {
  email: string;
  password: string;
};

export const LoginValidator = (values: LoginValidatorProps) => {
  const errors = {} as LoginValidatorProps;
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "A password is required";
  }

  return errors;
};

type SignUpValidatorProps = {
  fullname: string;
  email: string;
  password: string;
};

export const SignUpValidator = (values: SignUpValidatorProps) => {
  const errors = {} as SignUpValidatorProps;
  if (!values.fullname) {
    errors.fullname = "Full Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};
