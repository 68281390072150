import React, { useState } from "react";
import AppComponent from "../components/layouts/AppComponent";
import ContactBanner from "../assets/img/contact-banner.jpeg";
import { Link } from "react-router-dom";
import PAGES_URL from "../routes";
import { send } from "../helpers/Mail";
import Button from "../components/forms/Buttons";

const defaultState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const ContactUs = () => {
  const [state, setState] = useState<any>(defaultState);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const handleInputOnChange = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOnSubmit = (e: any) => {
    setLoading(true);
    const message: any = {
      message: `${state?.name} with phone number ${state.phone} & email address ${state.email} just sent a message \n\n${state?.message}`,
      from_name: state?.name,
      to_email: state?.email,
      from_email: state?.email,
      reply_tp: state?.email,
    };

    send(message).then((response: any) => {
      if (response.status === 200) {
        setLoading(false);
        setResponse(true);
      }
    });
  };
  const handleReset = () => {
    setResponse(false);
    setState(defaultState);
  };
  return (
    <AppComponent dark>
      <section className="pt-xl-8">
        <div className="container">
          <div className="row g-4 g-xxl-5">
            <div className="col-xl-9 mx-auto">
              <img
                src={ContactBanner}
                style={{ marginTop: "-250px" }}
                className=""
                alt="contact-bg"
              />

              <div className="row  mt-lg-n5 mb-5">
                <div className="col-11 col-lg-9 mx-auto">
                  <div className="card shadow p-4 p-sm-5 p-md-6">
                    <div className={response ? "d-none" : ""}>
                      <div className="card-header border-bottom px-0 pt-0 pb-5">
                        <nav className="mb-3" aria-label="breadcrumb">
                          <ol className="breadcrumb breadcrumb-dots pt-0">
                            <li className="breadcrumb-item">
                              <Link to={PAGES_URL.Landing}>Home</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Contact us
                            </li>
                          </ol>
                        </nav>
                        <h1 className="mb-3 h3">
                          Let's get your questions answered
                        </h1>
                        <p className="mb-0">
                          You can reach us anytime via{" "}
                          <Link to={"/"}>aria@metricise.co.uk</Link>
                        </p>
                      </div>
                      <form className="card-body px-0 pb-0 pt-5">
                        <div className="input-floating-label form-floating mb-4">
                          <input
                            onChange={handleInputOnChange}
                            name="name"
                            type="text"
                            className="form-control bg-transparent"
                            id="floatingName"
                            value={state.name}
                            placeholder="Your Name"
                          />
                          <label htmlFor="floatingName">Your name</label>
                        </div>
                        <div className="input-floating-label form-floating mb-4">
                          <input
                            type="email"
                            name="email"
                            value={state.email}
                            className="form-control bg-transparent"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={handleInputOnChange}
                          />
                          <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="input-floating-label form-floating mb-4">
                          <input
                            name="phone"
                            type="text"
                            className="form-control bg-transparent"
                            id="floatingNumber"
                            value={state.phone}
                            placeholder="Phone Number"
                            onChange={handleInputOnChange}
                          />
                          <label htmlFor="floatingNumber">Phone number</label>
                        </div>
                        <div className="input-floating-label form-floating mb-4">
                          <textarea
                            onChange={handleInputOnChange}
                            className="form-control bg-transparent"
                            name="message"
                            value={state.message}
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                          >
                            {state.message}
                          </textarea>
                          <label htmlFor="floatingTextarea2">Message</label>
                        </div>
                        <Button
                          loading={loading}
                          onClick={handleOnSubmit}
                          type="button"
                          className="btn btn-lg btn-primary mb-0"
                        >
                          Send a message
                        </Button>
                      </form>
                    </div>
                    <div className={!response ? "d-none" : ""}>
                      <div className="row mx-0 align-items-center">
                        <div className="col-md-12 border-end-md p-2 p-sm-5">
                          <h2 className="h3 mb-4 mb-sm-5  text-center">
                            Hey there!
                            <br />
                            Your Message has been sent Successfully.
                          </h2>
                          <img
                            className="d-block mx-auto"
                            src="https://cdn.pixabay.com/photo/2019/07/03/05/58/envelope-4313721_1280.png"
                            width="344"
                            alt="Illustartion"
                          />
                          <div className="mt-4 mt-sm-5 text-center">
                            Return to{" "}
                            <button
                              className="border-0 bg-white"
                              onClick={handleReset}
                            >
                              Contact Us
                            </button>{" "}
                            Page
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

export default ContactUs;
