export const splitString = (string: string) => {
  let newString = string;
  if (newString?.split("_")) {
    newString = newString.replaceAll("_", " ");
  }
  return newString;
};

export const formatDate = (dateString: any) => {
  const options: any = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const currencyFormat = (num: any) => {
  return "£ " +
    parseInt(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const capitalize = (mySentence: string) => {
  const words = mySentence.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1) + " ";
  }
  return words;
};
