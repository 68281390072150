import React, { useRef } from "react";

type Props = {
  draggable?: boolean;
  index: Number;
  onDragStart?: Function;
  onDrop: Function;
  children?: any;
};

const DraggableListItem = (props: Props) => {
  const itemRef = useRef<any>(null);

  const onDragStart = (e: any) => {
    // remove default drag ghost
    var event = e.touches ? e.touches[0] : e;
    if (!event) {
      e.dataTransfer.effectedAllowed = "move";
      e.dataTransfer.setDragImage(e.target, 50000, 50000);
    }

    // custom drag ghost
    let ghostNode = e.target.cloneNode(true);

    ghostNode.style.position = "absolute";

    // show ghost add mouse pointer position
    ghostNode.style.top = event.pageY - event.target.offsetHeight / 2 + "px";
    // ghostNode.style.left = e.pageX - e.target.offsetWidth / 2 + "px";

    // add width height to ghost node
    ghostNode.style.height = event.target.offsetHeight + "px";
    ghostNode.style.width = event.target.offsetWidth + "px";

    // add some style
    ghostNode.style.opacity = "0.8";
    ghostNode.style.pointerEvents = "none";

    // add id
    ghostNode.id = "ghostNode";

    document.body.prepend(ghostNode);

    // identify selected item
    itemRef.current.classList.add("dragstart");

    if (props.onDragStart) {
      props.onDragStart(props.index);
    }
  };

  // event when dragging
  const onDrag = (e: any) => {
    const ele = itemRef.current;
    if (!ele) {
      return;
    }
    // move ghost node with mouse
    // let ghostNode: any = document.getElementById("ghostNode");
    ele.style.top =
      e.pageY || e.targetTouches[0].pageY - e.target.offsetHeight / 2 + "px";
    ele.style.left =
      e.clientX || e.targetTouches[0].pageX - e.target.offsetWidth / 2 + "px";
  };

  // event when drag end
  const onDragEnd = () => {
    // remove ghost node
    document.querySelector("#ghostNode")?.remove();
    // remove selected item style
    itemRef.current.classList.remove("dragstart");
  };

  // event when drag over item
  const onDragEnter = () => itemRef.current.classList.add("dragover");

  // event when drag leave item
  const onDragLeave = () => itemRef.current.classList.remove("dragover");

  // add event for item can drop
  const onDragOver = (e: any) => e.preventDefault();

  // event when drop
  const onDrop = () => {
    itemRef.current.classList.remove("dragover");
    props.onDrop(props.index);
  };

  return (
    <div
      ref={itemRef}
      className="draggable-list__item hasTouchAction"
      draggable={props?.draggable}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onTouchStart={onDragStart}
      onTouchMove={onDrag}
      onTouchEnd={onDragEnd}
    >
      {props.children}
    </div>
  );
};

export default DraggableListItem;
