import { ClipLoader } from "react-spinners";
import LoadingBar from "react-top-loading-bar";

type Props = {
  loading: any;
  progress: any;
  topStyle?: any;
};

const LoaderComponent = ({ loading, progress, topStyle }: Props) => {
  return (
    <div className="">
      <LoadingBar color="#800412" height={3} progress={progress} />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: topStyle ? topStyle : "10px",
            right: "10px",
          }}
        >
          <ClipLoader size={25} color="#800412" />
        </div>
      )}
    </div>
  );
};

export default LoaderComponent;
