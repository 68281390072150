import React from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "../../../routes";

type Props = {
  name: string;
};

const Breadcrumbs = ({ name }: Props) => {
  return (
    <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={PAGES_URL.Landing}>Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="#">Account</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {name}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
