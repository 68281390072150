import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import { FaChildren, FaSchool, FaTrain, FaTree } from "react-icons/fa6";
import { FaMapMarker, FaShoppingBasket } from "react-icons/fa";
import { MdSchool } from "react-icons/md";

interface Prop extends React.InputHTMLAttributes<HTMLInputElement> {
  data: any;
}

const Map = ({ data, ...props }: Prop) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);

  const zoom = 14;
  maptilersdk.config.apiKey = process.env.REACT_APP_MAPTILER_API as string;
  const elSchool = document.createElement("div");
  elSchool.innerHTML = renderToString(<FaChildren size={25} color="#000" />);
  const elBuilding = document.createElement("div");
  elBuilding.innerHTML = renderToString(
    <FaMapMarker size={25} color="#800411" />
  );
  const elMarket = document.createElement("div");
  elMarket.innerHTML = renderToString(
    <FaShoppingBasket size={25} color="#000" />
  );
  const elSecond = document.createElement("div");
  elSecond.innerHTML = renderToString(<MdSchool size={25} color="#000" />);
  const elPrivate = document.createElement("div");
  elPrivate.innerHTML = renderToString(<FaSchool size={25} color="#000" />);
  const elGreen = document.createElement("div");
  elGreen.innerHTML = renderToString(<FaTree size={25} color="#000" />);
  const elStation = document.createElement("div");
  elStation.innerHTML = renderToString(<FaTrain size={25} color="#000" />);

  useEffect(() => {
    if (!(mapContainer.current && map)) return; // stops map from intializing more than once
    // el.className = "marker";
    // el.appendChild(<i className="fi-building"></i>) as HTMLElement;
    map.current = new maptilersdk.Map({
      container: mapContainer.current || undefined,
      style: maptilersdk.MapStyle.STREETS,
      center: [data.longitude, data.latitude],
      zoom: zoom,
    });
    new maptilersdk.Marker({ element: elBuilding })
      .setLngLat([data.longitude, data.latitude])
      .addTo(map.current);
    if (data.supermarket_proximity.distance) {
      new maptilersdk.Marker({ color: "#330000", element: elMarket })
        .setLngLat([
          data.supermarket_proximity.longitude,
          data.supermarket_proximity.latitude,
        ])
        .addTo(map.current);
    }
    if (data.primary_proximity.distance) {
      new maptilersdk.Marker({ element: elSchool })
        .setLngLat([
          data.primary_proximity.longitude,
          data.primary_proximity.latitude,
        ])
        .addTo(map.current);
    }
    if (data.secondary_proximity.distance) {
      new maptilersdk.Marker({ element: elSecond })
        .setLngLat([
          data?.secondary_proximity?.longitude,
          data?.secondary_proximity?.latitude,
        ])
        .addTo(map.current);
    }
    if (data.private_proximity.distance) {
      new maptilersdk.Marker({ element: elPrivate })
        .setLngLat([
          data.private_proximity.longitude,
          data.private_proximity.latitude,
        ])
        .addTo(map.current);
    }
    if (data.green_areas.distance) {
      new maptilersdk.Marker({ element: elGreen })
        .setLngLat([data.green_areas.longitude, data.green_areas.latitude])
        .addTo(map.current);
    }
    if (data.station_proximity.distance) {
      new maptilersdk.Marker({ element: elStation })
        .setLngLat([
          data.station_proximity.longitude,
          data.station_proximity.latitude,
        ])
        .addTo(map.current);
    }
  });

  return <div ref={mapContainer} {...props}></div>;
};

export default Map;
