import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../../assets/scss/nouislider.scss";
import classNames from "classnames";
import _ from "lodash";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  data: any;
  onChange: any;
  frontLabel?: string;
  backLabel?: string;
  name: string;
}

const MultiRangeSlider = ({
  data,
  onChange,
  frontLabel,
  backLabel,
  name,
  ...props
}: Props) => {
  const [records, setRecords] = useState<any>(data);

  let arr = records?.map((el: any) => el[name].distance || el[name]);
  const MaxFn = (arr: any) => _.maxBy<any>(arr, (o) => o);
  const MinFn = (arr: any) => _.minBy<any>(arr, (o) => o);
  let min = MinFn(arr);
  let max = MaxFn(arr);

  useEffect(() => {
    if (!records) return;
    setRecords(records);
  }, [records]);

  // console.log(min, max);

  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const step = Number(props.step);
  const minValRef = useRef<any>(null);
  const maxValRef = useRef<any>(null);
  const range = useRef<any>(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: any) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const handleOnChangeMin = (event: any) => {
    const valueMin = Math.min(+event.target.value, maxVal - (step || 1));
    setMinVal(valueMin);
    event.target.value = valueMin.toString();
    onChange({ [name]: { min: minVal, max: maxVal } });
  };

  const handleOnChangeMax = (event: any) => {
    const valueMax = Math.max(+event.target.value, minVal + (step || 1));
    setMaxVal(valueMax);
    event.target.value = valueMax.toString();
    onChange({ [name]: { min: minVal, max: maxVal } });
  };

  return (
    <div className="d-flex">
      <input
        type="range"
        min={min}
        max={max}
        name={name}
        // step={Number(minVal) ? 100 : 0.}
        value={minVal}
        ref={minValRef}
        onChange={handleOnChangeMin}
        className={classNames("thumb thumb--zindex-3", {
          "thumb--zindex-5": minVal > max - min,
        })}
        {...props}
      />
      <input
        type="range"
        min={min}
        max={max}
        name={name}
        // step={Number(maxVal) ? 100 : 0.1}
        value={maxVal}
        ref={maxValRef}
        onChange={handleOnChangeMax}
        className="thumb thumb--zindex-4"
        {...props}
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{`${
          frontLabel === "£"
            ? "£" + minVal?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : frontLabel === "£"
            ? "£" + minVal?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : minVal?.toFixed(2)
        }${backLabel ?? ""}`}</div>
        <div className="slider__right-value">{`${
          frontLabel === "£"
            ? "£" + maxVal?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : maxVal?.toFixed(2)
        }${backLabel ?? ""}`}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  // min: PropTypes.number.isRequired,
  // max: PropTypes.number.isRequired,
  name: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
