import { get_metrics_data, reduce_data_object } from "../../helpers/Metrics";
import supabase from "../../lib/supabase/client";
import {
  CLEAR_PROPERTY_FILTERS,
  SET_PROPERTY_COUNT,
  SET_PROPERTY_DATA,
  SET_PROPERTY_FILTERS,
} from "../reducers/types";
import { getActiveUserMetrics } from "./metric.action";

export const getAllProperty = () => async (dispatch: any) => {
  try {
    const metrics: any = await getActiveUserMetrics();
    const { data: payload } = await supabase.from("properties").select("*");
    let data = null;
    if (metrics?.length === 0) {
      data = await reduce_data_object(payload);
    } else {
      let type: any = metrics[0].type;
      data = await get_metrics_data({ type }, metrics, payload);
    }
    dispatch({ type: SET_PROPERTY_DATA, payload: data });
    dispatch({ type: SET_PROPERTY_COUNT, payload: data?.length });
    return data;
  } catch (error: any) {
  }
};

export const getSingleProperty = (params: any) => async (dispatch: any) => {
  try {
    const { data } = await supabase.from("properties").select("*").eq(
      "slug",
      params.id,
    ).single();

    let payload = await reduce_data_object([data]);
    return payload;
  } catch (error: any) {
  }
};

export const setPropertyData = (data: any) => async (dispatch: any) => {
  dispatch({ type: SET_PROPERTY_DATA, payload: data });
  dispatch({ type: SET_PROPERTY_COUNT, payload: data?.length });
};

export const setFilterOptions = (data: any) => async (dispatch: any) => {
  dispatch({ type: SET_PROPERTY_FILTERS, payload: data });
};

export const clearFilterOptions = () => async (dispatch: any) => {
  dispatch({ type: CLEAR_PROPERTY_FILTERS });
};
