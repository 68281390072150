import React, { useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { connect } from "react-redux";
import {
  createUserDefinedMetrics,
  setMetricPage,
  setSelectedMetrics,
} from "../../../redux/actions/metric.action";
import DraggableList from "../../../components/draggable/DraggableList";
import Header from "./Header";
import {
  clearFilterOptions,
  setPropertyData,
} from "../../../redux/actions/property";
import { useNavigate } from "react-router-dom";
import PAGES_URL from "../../../routes";
import { get_metrics_data } from "../../../helpers/Metrics";
import { HashLoader } from "react-spinners";
import { setFilteredData } from "../../../redux/actions/filter";

type Props = {
  selected: any;
  setPage: Function;
  setMetric: Function;
  search: any;
  user: any;
  setPropertyData: Function;
  updateMetricData: Function;
  properties: any;
  auth: any;
  resetFilters: Function;
  setFilters: Function;
};

const MetricReview = ({
  selected,
  setPage,
  setMetric,
  search,
  setPropertyData,
  updateMetricData,
  user,
  properties,
  auth,
  resetFilters,
  setFilters,
}: Props) => {
  const [button, setButton] = useState<any | boolean>(false);
  const navigate = useNavigate();

  const prev = () => {
    search.type === "Sale" ? setPage(4) : setPage(3);
  };

  const handleOnFinish = () => {
    setButton(true);
    if (!auth.user && !user) {
      navigate(PAGES_URL.Login);
    } else {
      get_metrics_data(search, selected).then((property: any) => {
        updateMetricData(selected, search, user.id);
        resetFilters();
        if (search.bedrooms || search.bathrooms) {
          const { type, ...searchData } = search;
          setFilters(searchData);
        }
        setPropertyData(property);

        if (property) {
          setButton(false);
          navigate(PAGES_URL.Property);
        }
      });
    }
  };

  return (
    <>
      <div className="container vh-100">
        <div className="row h-100">
          <Header
            progress={5}
            percent={100}
            title={`Rank all your metrics in order of importance`}
          />
          <div>
            <DraggableList
              setMetric={setMetric}
              data={selected}
              search={search}
              renderItemContent={(item: any) => {}}
            />
          </div>

          <div className="d-flex">
            {search.type === "Sale" ? (
              <button onClick={prev} className="btn  text-dark ">
                <BsArrowLeft size={40} className="ms-auto" />

                <span className=" fw-normal me-3"> Investment </span>
              </button>
            ) : (
              <button onClick={prev} className="btn  text-dark">
                <BsArrowLeft size={40} className="ms-auto" />

                <span className=" fw-normal me-3"> Area </span>
              </button>
            )}
            <button
              onClick={handleOnFinish}
              className="btn btn-sm text-dark border-0  ms-auto"
              disabled={button}
            >
              {!button ? (
                <>
                  <span className=" fw-normal me-3"> Finish </span>
                  <BsArrowRight size={40} className="ms-auto" />
                </>
              ) : (
                <HashLoader size={40} color={"#800411"} />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const MapStateToProps = (state: any) => ({
  selected: state.metric.selectedMetrics,
  search: state.metric.search,
  user: state.user.user,
  auth: state.auth,
  properties: state.properties.propertyData,
});

const MapDispatchToProps = (dispatch: any) => ({
  setPage: (data: any) => dispatch(setMetricPage(data)),
  setMetric: (data: any) => dispatch(setSelectedMetrics(data)),
  resetFilters: () => dispatch(clearFilterOptions()),
  setFilters: (data: any) => dispatch(setFilteredData(data)),
  setPropertyData: (data: any) => dispatch(setPropertyData(data)),
  updateMetricData: (data: any, search: any, id: any) =>
    dispatch(createUserDefinedMetrics(data, search, id)),
});

export default connect(MapStateToProps, MapDispatchToProps)(MetricReview);
