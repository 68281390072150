import React from "react";
import Header from "../Header";
import Footer from "../Footer";

type Props = {
  children: any;
  dark?: boolean;
};

const AppComponent = ({ children, dark }: Props) => {
  return (
    <>
      <main className="page-wrapper">
        <Header theme={dark} />
        <div className="main">{children}</div>
        <Footer />
      </main>
    </>
  );
};

export default AppComponent;
