import { toast } from "react-toastify";
import supabase from "../../lib/supabase/client";
import {
  SET_COUNTRIES_LIST,
  SET_INVESTMENT,
  SET_USER,
  SET_USER_AVATAR,
} from "../reducers/types";
import { setSelectedMetrics } from "./metric.action";

export const getUser = (user: any) => async (dispatch: any) => {
  try {
    let { data: profiles, error } = await supabase.from("profiles")
      .select("*, genders(*), countries(*), metrics(*)").eq("id", user.id)
      .single();
    if (error) throw error;
    dispatch(getUserInvestment(user));
    dispatch(getCountries());
    dispatch(setSelectedMetrics(profiles?.metrics));
    dispatch({ type: SET_USER, payload: profiles });
  } catch (err) {
  }
};

export const updateUserProfile = (formData: any) => async (dispatch: any) => {
  console.log(formData);

  try {
    delete formData.genders;
    delete formData.countries;

    const { data, error } = await supabase.from("profiles").update({
      ...formData,
    }).eq("id", formData.id).select("*, countries(*), genders(*)");

    if (error) throw error;
    dispatch(getUser(data));
  } catch (error) {
    return error;
  }
};

export const getUserInvestment = (user: any) => async (dispatch: any) => {
  try {
    let { data, error } = await supabase.from("investments")
      .select("*").eq("user_id", user.id).single();

    if (error) throw error;
    dispatch({ type: SET_INVESTMENT, payload: data });
    return data;
  } catch (error) {
    return error;
  }
};

export const getCountries = () => async (dispatch: any) => {
  try {
    const { data, error } = await supabase.from("countries").select("*");
    if (error) throw error;
    dispatch({ type: SET_COUNTRIES_LIST, payload: data });
  } catch (error: any) {
  }
};

export const getUserId = async () => {
  const { data: user } = await supabase.auth.getUser();
  return user.user?.id;
};

export const updateUserInvestment =
  (formData: any) => async (dispatch: any) => {
    try {
      console.log(formData);

      const { data: session } = await supabase.auth.getUser();
      const { data, error } = await supabase.from("investments").upsert({
        ...formData,
        user_id: session.user?.id,
      }).select("*").single();
      if (error) throw error;
      dispatch({ type: SET_INVESTMENT, payload: data });
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const updateAvatar = (formData: any) => async (dispatch: any) => {
  try {
    const id: any = await getUserId();
    const fileExt = formData.file.name.split(".").pop();
    const fileName = `${formData.name.replace(" ", "-")}.${fileExt}`;

    const { data, error } = await supabase
      .storage
      .from("metricise_public")
      .upload(
        "avatars/" + fileName,
        formData.file,
        {
          cacheControl: "3600",
          upsert: true,
        },
      );
    let public_path = process.env.REACT_APP_SUPABASE_URL +
      "/storage/v1/object/public/" +
      data?.fullPath;
    console.log(error, public_path);

    if (error) throw error;
    await supabase.from("profiles").update({ avatar: public_path }).eq(
      "id",
      id,
    );
    dispatch({ type: SET_USER_AVATAR, payload: public_path });
  } catch (e: any) {
    toast.error(e.message);
  }
};
