import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import AppComponent from "../../components/layouts/AppComponent";
import PAGES_URL from "../../routes";
import { fetchSingleMatchMates } from "../../redux/actions/mates.action";
import { useEffect, useState } from "react";
import Avatar from "../../assets/img/avatar.png";
import { getRelatedProperty } from "../../helpers/Mates";
import {
  BsBox,
  BsCalendar,
  BsCheck,
  BsClock,
  BsCurrencyPound,
} from "react-icons/bs";
import { formatDate, splitString } from "../../helpers/String";
import SingleMetricsMateSkeleton from "../../components/skeleton/SingleMetricsMate";
import Skeleton from "react-loading-skeleton";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { GrPieChart } from "react-icons/gr";

type Props = {
  fetch: Function;
};

const SingleMates = ({ fetch }: Props) => {
  const [state, setState] = useState<any>(null);
  const [related, setRelated] = useState<any>(null);
  const params = useParams();

  useEffect(() => {
    fetch(params).then((data: any) => {
      if (data) {
        setState(data);
        if (data.metrics.length > 0) {
          getRelatedProperty(data.metrics).then((records: any) => {
            console.log(records);
            setRelated(records);
          });
        }
      }
    });
  }, [fetch, params]);

  return (
    <AppComponent dark>
      <LoaderComponent
        topStyle={"100px"}
        progress={state ? 100 : 25}
        loading={state ? false : true}
      />
      {state ? (
        <div className="container mt-5 mb-md-5" id="metrics_mates_single">
          <nav className="mb-5 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.Landing}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.MatchedMates}>Find My MetricMates</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {state?.fullname}
              </li>
            </ol>
          </nav>
          <div className="row">
            <aside className="col-lg-3 col-md-3 mb-5">
              <div className="pe-lg-3">
                <img
                  className="d-block rounded-circle mx-auto mx-md-0 mb-3"
                  src={state?.avatar || Avatar}
                  width="120"
                  alt={state?.fullname}
                />
                <h2 className="h4 text-center text-md-start mb-0">
                  {state?.fullname}
                </h2>
                <p className="text-center text-md-start fs-sm fw-normal mb-0 pb-1">
                  United Kingdom
                </p>
                {/* <div className="d-flex justify-content-center justify-content-md-start border-bottom mb-3 pb-3">
                  <span className="star-rating">
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                  </span>
                  <div className="text-muted ms-2">(45 reviews)</div>
                </div> */}
                <ul className="d-table list-unstyled mx-md-0 ">
                  {/* <li className="mb-">
                    <Link
                      className="nav-link fw-normal p-0"
                      to={`tel:${state?.phone}`}
                    >
                      {state?.phone && (
                        <>
                          <i className="fi-phone text-primary mt-n1 me-2 align-middle"></i>
                          {state?.phone}
                        </>
                      )}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      className="nav-link fw-normal p-0"
                      to={`mailto:${state?.email}`}
                    >
                      <i className="fi-mail text-primary mt-n1 me-2 align-middle"></i>
                      {state?.email}
                    </Link>
                  </li> */}
                </ul>
                <div className="col-lg-10">
                  <h6 className="text-dark mb-3">Metric Base</h6>

                  <ul className="list-unstyled">
                    {state?.metrics.length > 0 ? (
                      state?.metrics.slice(0, 5).map((el: any, index: any) => (
                        <div className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 bg-secondary rounded-pill mb-2">
                          <div
                            className={`icon-box-media bg-primary text-white rounded-circle fs-xs fw-bolder p-0 `}
                          >
                            {++index}
                          </div>
                          <h3 className="icon-box-title fs-xs ps-3 pe-2 fw-normal py-2 mb-0">
                            {el.lead} {splitString(el?.name)}
                          </h3>
                        </div>
                      ))
                    ) : (
                      <i>{state.fullname} metrics would show here</i>
                    )}
                  </ul>
                </div>
              </div>
            </aside>
            <div className="col-lg-5 col-md-5">
              <div className="border-bottom pb-4 mb-4">
                <p className="fs-sm mb-0 fw-normal text-dark">
                  <p className="text-dark mb-1">Personal Biography</p>
                  {state?.bio ? (
                    state?.bio
                  ) : (
                    <i>
                      {state.fullname} has not added a personal biography yet
                    </i>
                  )}
                </p>
              </div>
              <div className="row">
                <p className="text-dark mb-1">Area Of Interest</p>
                <div className="mb-4 pb-md-3">
                  {state?.area_of_interest ? (
                    state?.area_of_interest
                  ) : (
                    <i>
                      {state.fullname} has not added an area of interest yet
                    </i>
                  )}
                </div>

                <p className="text-dark mb-1">Goals & Objectives</p>
                <div className="mb-4 pb-md-3">
                  {state?.goals ? (
                    state?.goals
                  ) : (
                    <i>
                      {state.fullname} has not added any goals and objectives
                      yet
                    </i>
                  )}
                </div>

                {state && (
                  <p className="text-dark mb-1">Investment Preference</p>
                )}
                <div className="mb-4 pb-md-3">
                  <ul className="list-unstyled row text-dark  gy-1 mb-1 text-nowrap">
                    <li className="d-block">
                      <GrPieChart className="text-dark mt-n1 me-2 fs-lg align-middle" />

                      {state?.investments ? (
                        <>
                          Preferred Share: {state.investments.preferred_share}
                        </>
                      ) : (
                        "Preferred Share: Not Set"
                      )}
                    </li>
                    <li className="d-block">
                      <BsClock className="text-dark mt-n1 me-2 fs-lg align-middle" />
                      {state?.investments ? (
                        <>
                          {state.investments.years_of_experience} Years Of
                          Experience
                        </>
                      ) : (
                        "Years Of Experience: Not Set"
                      )}
                    </li>
                    <li className="d-block">
                      <BsBox className="text-dark mt-n1 me-2 fs-lg align-middle" />
                      {state?.investments
                        ? `${state.investments.risk_tolerance}  `
                        : "Risk Tolerance: Not Set"}
                    </li>
                    <li className="d-block">
                      <BsCurrencyPound className="text-dark mt-n1 me-2 fs-lg align-middle" />
                      {state?.investments
                        ? `${state.investments.usable_bankroll} Usable Bankroll `
                        : "Usable Bankroll: Not Set"}
                    </li>
                    <li className="d-block">
                      <BsCalendar className="text-dark mt-n1 me-2 fs-lg align-middle" />
                      {state?.investments
                        ? ` Investment-Ready from ${formatDate(
                            state.investments.invest_from
                          )} `
                        : "Invested from: Not Set"}
                    </li>
                    <li className="d-block">
                      <BsCheck className="text-dark mt-n1 me-2 fs-lg align-middle" />
                      Open To Network
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="row">
                <h6>Top Related Match</h6>
                {!related && (
                  <i className="mb-">
                    {state.fullname} does not have any available related
                    property matches
                  </i>
                )}
                {related?.length > 0
                  ? related?.map((el: any, key: any) => (
                      <div className="col-lg-4 pb-2 " key={key}>
                        <div className="position-relative">
                          <div className="position-relative mb-3">
                            <img
                              style={{ width: "200px", height: "100px" }}
                              className="rounded-3 img-fluid"
                              src={el.images[0]}
                              alt={el.address}
                            />
                          </div>
                          <h3 className="mb-2 fs-lg">
                            <Link
                              className="nav-link stretched-link fw-normal fs-sm text-dark"
                              to={`${PAGES_URL.SingleProperty}/${el.slug}`}
                            >
                              {el.address.substring(0, 20)}...
                            </Link>
                          </h3>
                          <div className="card-footer d-flex align-items-center text-nowrap">
                            <ul className="list-unstyled d-flex fs-sm">
                              <li className="fs-sm">
                                <i className="fi-bed mt-n1 me-2 fs-lg align-middle"></i>
                                Bed ({el.bedrooms})
                              </li>
                              <li className="ms-3">
                                <i className="fi-bath mt-n1 me-2 fs-lg align-middle"></i>
                                Bath ({el.bedrooms})
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                  : Array(6)
                      .fill("Dummu")
                      .map(() => (
                        <div className="col-lg-4 pb-2">
                          <div className="position-relative">
                            <div className="position-relative mb-3">
                              <Skeleton width={200} height={100} />
                            </div>
                            <h3 className="mb-2 fs-lg">
                              <Skeleton />
                            </h3>
                            <div className="card-footer d-flex align-items-center text-nowrap">
                              <ul className="list-unstyled d-flex fs-sm">
                                <li className="fs-sm">
                                  <Skeleton width={60} />
                                </li>
                                <li className="ms-3">
                                  <Skeleton width={60} />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="row">
                <h6> Top Properties</h6>
                {!related && (
                  <i className="mb-">
                    {state.fullname} does not have any available related
                    property matches
                  </i>
                )}
                {related?.length > 0
                  ? related?.map((el: any, key: any) => (
                      <div className="col-6 col-md-6" key={key}>
                        <div className="position-relative ">
                          <div className="position-relative mb-3">
                            <img
                              className="rounded-3 metrics-img"
                              src={el.images[0]}
                              alt={el.address}
                            />
                          </div>
                          <h3 className="mb-2 fs-lg">
                            <Link
                              className="nav-link stretched-link fw-normal fs-sm text-dark"
                              to={`${PAGES_URL.SingleProperty}/${el.slug}`}
                            >
                              {el.address.substring(0, 20)}...
                            </Link>
                          </h3>
                          <div className="card-footer d-flex align-items-center text-nowrap">
                            <ul className="list-unstyled d-flex fs-sm">
                              <li className="fs-sm">
                                <i className="fi-bed mt-n1 me-2 fs-lg align-middle"></i>
                                {el.bedrooms === "0"
                                  ? "Studio"
                                  : el.bedrooms + " Bed"}
                              </li>
                              <li className="ms-3">
                                <i className="fi-bath mt-n1 me-2 fs-lg align-middle"></i>
                                {el.bathrooms} Bath
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                  : Array(6)
                      .fill("Dummu")
                      .map(() => (
                        <div className="col-lg-4 pb-2">
                          <div className="position-relative">
                            <div className="position-relative mb-3">
                              <Skeleton width={200} height={100} />
                            </div>
                            <h3 className="mb-2 fs-lg">
                              <Skeleton />
                            </h3>
                            <div className="card-footer d-flex align-items-center text-nowrap">
                              <ul className="list-unstyled d-flex fs-sm">
                                <li className="fs-sm">
                                  <Skeleton width={60} />
                                </li>
                                <li className="ms-3">
                                  <Skeleton width={60} />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SingleMetricsMateSkeleton />
      )}
    </AppComponent>
  );
};

const MapStateToProps = (state: any) => ({});

const MapDispatchToProps = (dispatch: any) => ({
  fetch: (user_id: string) => dispatch(fetchSingleMatchMates(user_id)),
});

export default connect(MapStateToProps, MapDispatchToProps)(SingleMates);
