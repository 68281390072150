/** @format */

import {
  CLEAR_METRIC_DATA,
  SET_MAPPED_PROPERTY,
  SET_METRIC_PAGE,
  SET_METRIC_SEARCH,
  SET_SELECTED_METRICS,
} from "./types";

const initialState = {
  search: null,
  selectedMetrics: [],
  pageId: 1,
};

type Props = { type: string; payload?: any }; // Define the Action type

export default function metricReducer(state = initialState, action: Props) {
  switch (action.type) {
    case SET_METRIC_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_MAPPED_PROPERTY:
      return {
        ...state,
        mapped: action.payload,
      };
    case SET_SELECTED_METRICS:
      return {
        ...state,
        selectedMetrics: action.payload,
      };
    case SET_METRIC_PAGE:
      return {
        ...state,
        pageId: action.payload,
      };
    case CLEAR_METRIC_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
