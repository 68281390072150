/** @format */

import {
  CLEAR_PROPERTY_DATA,
  CLEAR_PROPERTY_FILTERS,
  SET_PROPERTY_COUNT,
  SET_PROPERTY_DATA,
  SET_PROPERTY_FILTERS,
  SET_SELECTED_PROPERTY_DATA,
} from "./types";

const initialState = {
  propertyData: null,
  totalCount: 0,
  filters: null,
};

type Props = { type: string; payload?: any }; // Define the Action type

export default function propertyReducer(state = initialState, action: Props) {
  switch (action.type) {
    case SET_PROPERTY_DATA:
      return {
        ...state,
        propertyData: action.payload,
      };
    case SET_SELECTED_PROPERTY_DATA:
      return {
        ...state,
        selectedPropertyData: action.payload,
      };
    case SET_PROPERTY_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_PROPERTY_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case CLEAR_PROPERTY_FILTERS:
      return {
        ...state,
        filters: null,
      };
    case CLEAR_PROPERTY_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
