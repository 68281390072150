import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { connect } from "react-redux";
import {
  setMetricPage,
  setSelectedMetrics,
} from "../../../redux/actions/metric.action";
import MetricItem from "./MetricItem";
import Header from "./Header";

const defaultState = [
  { name: "population", lead: ["Highest", "Lowest"] },
  { name: "population_density", lead: ["Highest", "Lowest"] },
  { name: "average_age", lead: ["Highest", "Lowest"] },
  { name: "crime", lead: ["Most", "Least"] },
  { name: "primary_proximity", lead: ["Furthest", "Closest"] },
  { name: "secondary_proximity", lead: ["Furthest", "Closest"] },
  { name: "private_proximity", lead: ["Furthest", "Closest"] },
  { name: "station_proximity", lead: ["Furthest", "Closest"] },
  { name: "green_areas", lead: ["Furthest", "Closest"] },
  { name: "supermarket_proximity", lead: ["Furthest", "Closest"] },
  { name: "broadband_speed", lead: ["Fastest", "Slowest"] },
];

const Area = ({ setPage, setMetric, data, search }: any) => {
  const [state, setState] = useState<any>(data);

  const prev = () => {
    setPage(2);
  };

  const next = () => {
    search.type === "Sale" ? setPage(4) : setPage(5);
  };

  useEffect(() => {
    setMetric(state);
  }, [setMetric, state]);

  return (
    <div className="container vh-100">
      <div className="row h-100">
        <Header
          progress={3}
          percent={60}
          title={`Area Metrics`}
          subtext="Select only the metrics that matter to you."
        />
        <section className="position-relative rounded-xxl-4 pt-md-3 zindex-5 ">
          <div className="container  vw-100">
            <h4 className="h4  fw-normal ">
              If all other things were equal, I would choose the area with
              the...
            </h4>
            <div className="d-flex flex-wrap ">
              {defaultState &&
                defaultState?.map((item: any) => (
                  <MetricItem data={item} state={state} setState={setState} />
                ))}
            </div>
          </div>
        </section>

        <div className="d-flex">
          <button
            onClick={prev}
            className="btn text-dark  "
            style={{ fontSize: "69px" }}
          >
            <BsArrowLeft size={40} className="" />{" "}
            <span className=" fw-normal ms-3"> Property </span>
          </button>
          {search.type === "Sale" ? (
            <button onClick={next} className="btn  text-dark ms-auto">
              <span className=" fw-normal me-3"> Investment </span>

              <BsArrowRight size={40} className="ms-auto" />
            </button>
          ) : (
            <button onClick={next} className="btn  text-dark ms-auto">
              <span className=" fw-normal me-3"> Importances </span>

              <BsArrowRight size={40} className="ms-auto" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  data: state.metric.selectedMetrics,
  search: state.metric.search,
  pageId: state.metric.pageId,
});

const mapDispatchToProps = (dispatch: any) => ({
  setPage: (data: any) => dispatch(setMetricPage(data)),
  setMetric: (data: any) => dispatch(setSelectedMetrics(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Area);
