import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { connect } from "react-redux";
import {
  setMetricPage,
  setSelectedMetrics,
} from "../../../redux/actions/metric.action";
import MetricItem from "./MetricItem";
import Header from "./Header";

const defaultState = [
  { name: "median_rents", lead: ["Highest", "Lowest"] },
  { name: "rental_affordability", lead: ["Most", "Least"] },
  { name: "gross_yields", lead: ["Highest", "Lowest"] },
  { name: "listing_age", lead: ["Oldest", "Newest"] },
  { name: "area_leverage", lead: ["Most", "Least"] },
  { name: "debt_per_building", lead: ["Highest", "Lowest"] },
  { name: "mortgage_affordability", lead: ["Most", "Least"] },
  { name: "credit_score", lead: ["Highest", "Lowest"] },
  { name: "mean_income", lead: ["Highest", "Lowest"] },
];

type Props = {
  setPage: any;
  data: any;
  setMetric?: any;
};

const Investment = ({ setPage, data, setMetric }: Props) => {
  const [state, setState] = useState<any>(data);
  const prev = () => {
    setPage(3);
  };

  const next = () => {
    setPage(5);
  };

  useEffect(() => {
    setMetric(state);
  }, [setMetric, state]);
  return (
    <div className="container vh-100">
      <div className="row h-100">
        <Header
          progress={4}
          percent={80}
          title={`Investment Metrics`}
          subtext="Select only the metrics that matter to you."
        />
        <section className="position-relative rounded-xxl-4 pt-md-3 zindex-5 ">
          <div className="container  vw-100">
            <h4 className="h4  fw-normal ">
              If all other things were equal, I would invest in the property
              with the...
            </h4>
            <div className="d-flex flex-wrap flex-column flex-sm-row">
              {defaultState &&
                defaultState?.map((item: any) => (
                  <MetricItem data={item} state={state} setState={setState} />
                ))}
            </div>
          </div>
        </section>

        <div className="d-flex">
          <button
            onClick={prev}
            className="btn text-dark  "
            style={{ fontSize: "69px" }}
          >
            <BsArrowLeft size={40} className="" />{" "}
            <span className=" fw-normal ms-3"> Area </span>
          </button>
          <button onClick={next} className="btn  text-dark ms-auto">
            <span className=" fw-normal me-3"> Importances </span>

            <BsArrowRight size={40} className="ms-auto" />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  data: state.metric.selectedMetrics,
  pageId: state.metric.pageId,
});

const mapDispatchToProps = (dispatch: any) => ({
  setPage: (data: any) => dispatch(setMetricPage(data)),
  setMetric: (data: any) => dispatch(setSelectedMetrics(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Investment);
