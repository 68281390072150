import supabase from "../../lib/supabase/client";
import {
  CLEAR_METRIC_DATA,
  CLEAR_PROPERTY_DATA,
  CLEAR_PROPERTY_FILTERS,
  CLEAR_USER_DATA,
  LOGOUT,
  SET_SELECTED_METRICS,
} from "../reducers/types";
import {
  createUserDefinedMetrics,
  getActiveUserMetrics,
} from "./metric.action";
import { getUser } from "./user.action";

export const loginAction = (body: any) => async (dispatch: any) => {
  try {
    let formData = body.state || body;

    const { data, error } = await supabase.auth.signInWithPassword(formData);
    if (error) throw error;
    const { data: session } = await supabase.auth.setSession(data.session);
    const metrics = getActiveUserMetrics();
    if (body.selectedMetrics?.length > 0) {
      const { search, selectedMetrics } = body;
      dispatch(
        createUserDefinedMetrics(selectedMetrics, search, session.user?.id),
      );
    }
    dispatch(getUser(session.user));
    dispatch({ type: SET_SELECTED_METRICS, payload: await metrics });

    return session;
  } catch (error: any) {
    return error;
  }
};

/**
 * Register Action
 * Pass user registration field to supabase
 * @param user
 * @returns
 */

export const registerAction = (body: any) => async (dispatch: any) => {
  try {
    const { fullname, email, password } = body;
    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: { data: { fullname, email } },
    });
    if (error) throw error;
  } catch (error: any) {
    return error;
  }
};

export const setSession = (session: any) => {
  const { refresh_token, access_token } = session;
  supabase.auth.setSession({ refresh_token, access_token });
};

export const logOutAction = () => async (dispatch: any) => {
  try {
    await supabase.auth.signOut();
    dispatch({ type: CLEAR_PROPERTY_FILTERS });
    dispatch({ type: CLEAR_PROPERTY_DATA });
    dispatch({ type: CLEAR_USER_DATA });
    dispatch({ type: CLEAR_METRIC_DATA });
    dispatch({ type: LOGOUT });
  } catch (err) {}
};
