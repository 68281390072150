import React, { useEffect, useState } from "react";
import AppComponent from "../../components/layouts/AppComponent";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import SwiperClass from "swiper/types/swiper-class";
import "swiper/css";
import "swiper/css/navigation";

import PAGES_URL from "../../routes";
import { connect } from "react-redux";
import { getSingleProperty } from "../../redux/actions/property";
import { BsPlusCircle } from "react-icons/bs";
import {
  capitalize,
  currencyFormat,
  formatDate,
  splitString,
} from "../../helpers/String";
import SinglePropertySkeleton from "../../components/skeleton/SingleProperty";
import { relevant_metrics_text } from "../../helpers/Metrics";
import Map from "./components/Map";
import { FaMapMarker, FaShoppingBasket, FaTrain } from "react-icons/fa";
import { FaChildren, FaSchool, FaTree } from "react-icons/fa6";
import { MdSchool } from "react-icons/md";

const SingleProperty = ({ fetch, search, selected }: any) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [state, setState] = useState<any>(null);
  const [showFullDescription, setFullDescription] = useState(false);

  const params = useParams();

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const description = showFullDescription
    ? state?.description
    : state?.description.slice(0, 500) + "";

  useEffect(() => {
    fetch(params).then((item: any) => {
      console.log(item[0]);
      setState(item[0]);
    });
  }, [fetch, params]);

  const property_type = (type: string) => {
    let label = "Not Available";
    if (type === "S") {
      label = "Semi Detached";
    } else if (type === "F") {
      label = "Flat";
    } else if (type === "D") {
      label = "Detached";
    } else if (type === "T") {
      label = "Terraced";
    }
    return label;
  };

  return (
    <AppComponent>
      {state ? (
        <section className="container mt-3 ">
          <nav className="mb-3 pt-md-3" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.Landing}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.Property}>Property </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {state?.address}
              </li>
            </ol>
          </nav>
          <div className="row gy-5 pt-lg-2">
            <div className="col-lg-8">
              <div className="d-flex flex-column">
                <div className="order-lg-1 order-2">
                  <div className="tns-carousel-wrapper">
                    <Swiper
                      navigation
                      spaceBetween={10}
                      thumbs={{
                        swiper:
                          thumbsSwiper && !thumbsSwiper.destroyed
                            ? thumbsSwiper
                            : null,
                      }}
                      modules={[FreeMode, Navigation, Thumbs]}
                    >
                      {state?.images &&
                        state?.images.map((item: any, idx: number) => (
                          <SwiperSlide key={idx}>
                            <img
                              src={item}
                              className="swiper-img-cover"
                              alt=""
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={5}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbnail"
                  >
                    {state?.images &&
                      state?.images.map((item: any, idx: number) => (
                        <SwiperSlide key={idx}>
                          <img
                            src={item}
                            className="swiper-img-thumbnail"
                            alt=""
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="order-lg-2 order-1 mt-4">
                  <h1 className="h2  fw-normal mb-1">{state?.agent_name}</h1>
                  <p className="mb-2 pb-1 fs-lg text-dark fw-normal">
                    {state?.address}
                  </p>
                  <ul className="list-unstyled d-flex fs-sm">
                    {state?.bedrooms && (
                      <li className="">
                        <i className="fi-bed mt-n1 me-2 fs-lg align-middle"></i>
                        {state?.bedrooms} Bed
                      </li>
                    )}
                    {state?.bathrooms && (
                      <li className="ms-3">
                        <i className="fi-bath mt-n1 me-2 fs-lg align-middle"></i>
                        {state?.bathrooms} Bath
                      </li>
                    )}
                    {state?.parking && (
                      <li className="ms-3">
                        <i className="fi-car mt-n1 me-2 fs-lg align-middle"></i>
                        Parking
                      </li>
                    )}
                    {state?.square_footage && (
                      <li className="ms-3">
                        <i className="fi-map mt-n1 me-2 fs-lg align-middle"></i>
                        {state?.square_footage}
                      </li>
                    )}
                    <li className="ms-3">
                      <i className="fi-home  mt-n1 me-2 fs-lg align-middle"></i>
                      {property_type(state?.type)}
                    </li>
                  </ul>
                </div>
              </div>
              <h2 className="h6 fw-bold fs-lg mt-5 mb-2">
                {description && "Overview"}
              </h2>
              <p className="mb-4 pb-2 fw-normal">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.replace(
                      /(.)(\.)(.)/g,
                      "$1<br/><br/>$3"
                    ),
                  }}
                />
                <Link
                  className="fs-sm"
                  to="#"
                  onClick={showFullDescriptionHandler}
                >
                  Read {showFullDescription ? "Less" : "More"}
                </Link>
              </p>

              {state?.floorplans.length > 0 && (
                <div className="my-4 row">
                  <h2 className="h6 fw-bold fs-lg">Floor Plans</h2>
                  {state?.floorplans.map((item: any, idx: number) => (
                    <div className="col">
                      <img
                        key={idx}
                        style={{ width: "auto", height: "400px" }}
                        src={item}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
              <h2 className="h6 fw-bold fs-lg">Map</h2>

              <div className="position-relative">
                <div className="position-relative">
                  <Map
                    data={state}
                    className="property_map"
                    style={{ height: "400px" }}
                  />
                </div>
                <a
                  className=" fw-normal text-decoration-none position-absolute top-0 ms-3 mt-3 bg-light p-3 text-dark"
                  style={{ fontSize: "10px", width: "200px" }}
                  href={"#s"}
                  data-bs-toggle-className="invisible"
                  data-bs-target="#map"
                >
                  <div className="row align-items-center">
                    <span className="me-4 fs-xs fw-normal">
                      <FaMapMarker /> Building
                    </span>
                    <span className="me-4 fs-xs fw-normal">
                      <FaChildren /> Primary School
                    </span>
                    <span className="me-4 fs-xs fw-normal">
                      <MdSchool /> Secondary School
                    </span>
                    <span className="me-4 fs-xs fw-normal">
                      <FaSchool /> Private School
                    </span>
                    <span className="me-4 fs-xs fw-normal">
                      <FaShoppingBasket /> Supermarket
                    </span>
                    <span className="me-4 fs-xs fw-normal">
                      <FaTrain /> Station
                    </span>
                    <span className="fs-xs fw-normal">
                      <FaTree /> Park & Garden
                    </span>
                  </div>
                </a>
              </div>
              <hr />
              <h2 className="h6 fw-bold fs-lg mt-3">Rental Agent</h2>
              <div className="row">
                <aside className="col-lg-5 col-md-4 mb-5">
                  <div className="pe-lg-3">
                    <img
                      className="d-block rounded-circle mx-auto mx-md-0 mb-3"
                      src="https://png.pngtree.com/png-vector/20221101/ourmid/pngtree-house-property-logo-real-estate-design-buildings-clipart-png-image_6405730.png"
                      width="120"
                      alt="Floyd Miles"
                    />
                    <h2 className="h5 text-center text-md-start mb-1 fw-normal">
                      {state?.agent_name}
                    </h2>
                    <p className="text-center text-md-start mb-2 fw-normal pb-1">
                      {state?.address}
                    </p>

                    <div className="text-center text-md-start pt-md-2 mt-2">
                      <Link
                        className="btn btn-primary"
                        to={`tel:${state?.agent_phone_number}`}
                      >
                        <i className="fi-phone fs-sm me-2"></i>Speak to Agent
                      </Link>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
            <aside className="col-lg-4" style={{ marginTop: "-6rem" }}>
              <div className="sticky-top pt-5 ps-lg-5">
                <div className="d-flex align-items-center justify-content-between pt-5 mb-3">
                  <div>{/* <Badge weight={state?.ranking} /> */}</div>
                  <div className="text-nowrap">
                    <button
                      className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2"
                      type="button"
                      data-bs-toggle="tooltip"
                      title="Add to Wishlist"
                    >
                      <i className="fi-heart"></i>
                    </button>
                    <div
                      className="dropdown d-inline-block"
                      data-bs-toggle="tooltip"
                      title="Share"
                    >
                      <button
                        className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2 mb-2"
                        type="button"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fi-share"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end my-1">
                        <button className="dropdown-item" type="button">
                          <i className="fi-facebook fs-base opacity-75 me-2"></i>
                          Facebook
                        </button>
                        <button className="dropdown-item" type="button">
                          <i className="fi-twitter fs-base opacity-75 me-2"></i>
                          Twitter
                        </button>
                        <button className="dropdown-item" type="button">
                          <i className="fi-instagram fs-base opacity-75 me-2"></i>
                          Instagram
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {state?.listing === "Sale" ? (
                  <h3 className="h5 mb-2">Selling Price:</h3>
                ) : (
                  <h3 className="h5 mb-2">Monthly Rent:</h3>
                )}
                <h2 className="h2 fw-normal mb-4 pb-2">
                  {state?.price && currencyFormat(state?.price)}
                  <span className="d-inline-block ms-1 fs-base fw-normal text-body">
                    {state?.listing !== "Sale" ?? "/month"}
                  </span>
                </h2>
                {selected.length > 0 && (
                  <div
                    className="card bg-secondary mb-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#metrics"
                  >
                    <div className="card-body">
                      <div className="d-flex">
                        <h3 className="h6 fw-normal pt-1 mb-0">
                          Your Relevant Metrics
                        </h3>
                        <BsPlusCircle className="ms-auto" />
                      </div>
                    </div>
                    <div
                      className="collapse show"
                      id="metrics"
                      data-bs-parent="#accordionCards"
                    >
                      <div className="card-body mt-n1 pt-0">
                        <ul className="list-unstyled row row-cols-md-q row-cols-1 gy-2 mb-0 text-nowrap">
                          {selected.map((el: any) => (
                            <li className="col fs-sm border-bottom pb-2">
                              <i className="fi-star  me-2 fs-sm align-middle"></i>
                              {capitalize(splitString(el.name))}
                              {": "}
                              {relevant_metrics_text(el.name, state[el.name])}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="card bg-secondary mb-2"
                  data-bs-toggle="collapse"
                  data-bs-target="#cardCollapse1"
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="h6 fw-normal pt-1 mb-0">Properties</h3>
                      <BsPlusCircle className="ms-auto" />
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="cardCollapse1"
                    data-bs-parent="#accordionCards"
                  >
                    <div className="card-body mt-n1 pt-0">
                      <ul className="list-unstyled row row-cols-md-q row-cols-1 gy-2 mb-0 text-nowrap">
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-home  me-2 fs-sm align-middle"></i>
                          Type: {property_type(state?.type)}
                        </li>
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-bed  me-2 fs-sm align-middle"></i>
                          Bedrooms: {state?.bedrooms}
                        </li>
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-bath  me-2 fs-sm align-middle"></i>
                          Bathrooms: {state?.bathrooms}
                        </li>
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-car  me-2 fs-sm align-middle"></i>
                          Parking: {state?.parking ? "Yes" : "No"}
                        </li>

                        <li className="col fs-sm">
                          <i className="fi-building  me-2 fs-sm align-middle"></i>
                          Square Footage:{" "}
                          {state?.square_footage
                            ? state?.square_footage
                            : " Not available"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-secondary collapsed mb-2"
                  data-bs-toggle="collapse"
                  data-bs-target="#cardCollapse2"
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="h6 fw-normal pt-1 mb-0">Area</h3>
                      <BsPlusCircle className="ms-auto" />
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="cardCollapse2"
                    data-bs-parent="#accordionCards"
                  >
                    <div className="card-body mt-n1 pt-0">
                      <ul className="list-unstyled row row-cols-md-1 row-cols-1 gy-2 mb-0 text-nowrap">
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-user me-1 fs-sm align-middle"></i>
                          Population: {state?.population}
                        </li>
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-users me-1 fs-sm align-middle"></i>
                          Density: {state?.population_density}
                        </li>
                        <li className="col fs-sm border-bottom pb-2">
                          <i className="fi-star me-1 fs-sm align-middle"></i>
                          Crime Rate:{" "}
                          {state?.crime ? state?.crime.toFixed(2) : "Nil"}
                        </li>
                        <li className="col fs-sm ">
                          <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                          Broadband:
                          {state?.broadband_speed
                            ? state?.broadband_speed.toFixed(2) + "Mbps"
                            : ""}
                        </li>
                        {/* <li className="col fs-sm ">
                          <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                          Broadband:
                          {state?.broadband_speed
                            ? state?.broadband_speed.toFixed(2) + "Mbps"
                            : ""}
                        </li>
                        <li className="col fs-sm ">
                          <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                          Broadband:
                          {state?.broadband_speed
                            ? state?.broadband_speed.toFixed(2) + "Mbps"
                            : ""}
                        </li>
                        <li className="col fs-sm ">
                          <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                          Broadband:
                          {state?.broadband_speed
                            ? state?.broadband_speed.toFixed(2) + "Mbps"
                            : ""}
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                {state.listing === "Sale" && (
                  <div
                    className="card bg-secondary collapsed mb-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#cardCollapse3"
                  >
                    <div className="card-body">
                      <div className="d-flex">
                        <h3 className="h6 fw-normal pt-1 mb-0">Investments</h3>
                        <BsPlusCircle className="ms-auto" />
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="cardCollapse3"
                      data-bs-parent="#accordionCards"
                    >
                      <div className="card-body mt-n1 pt-0">
                        <ul className="list-unstyled row row-cols-md-1 row-cols-1 gy-2 mb-0 text-nowrap">
                          <li className="col fs-sm border-bottom pb-2">
                            <i className="fi-car me-1 fs-sm align-middle "></i>
                            Gross Yields: {state?.gross_yields?.toFixed(2)}%
                          </li>
                          <li className="col fs-sm border-bottom pb-2">
                            <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                            Median Rents: {currencyFormat(state?.median_rents)}
                          </li>
                          <li className="col fs-sm border-bottom pb-2">
                            <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                            Mean Income: {currencyFormat(state?.mean_income)}
                          </li>
                          <li className="col fs-sm border-bottom pb-2">
                            <i className="fi-user me-1 fs-sm align-middle"></i>
                            Area Leverage: {state?.area_leverage?.toFixed(
                              2
                            )}{" "}
                            LTV
                          </li>
                          <li className="col fs-sm border-bottom pb-2">
                            <i className="fi-users me-1 fs-sm align-middle"></i>
                            Debt Per Building:{" "}
                            {currencyFormat(state?.debt_per_building)}
                          </li>
                          <li className="col fs-sm ">
                            <i className="fi-wifi  me-1 fs-sm align-middle"></i>
                            Mortgage Affordability Income:
                            {state?.mortgage_affordability?.toFixed(2)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {/* <Link
                className="d-inline-block mb-4 pb-2 mt-4 text-decoration-none"
                to="#"
              >
                <i className="fi-help me-1 mt-n1 align-middle"></i>Frequently
                asked questions
              </Link> */}
                {state?.features.length > 0 && (
                  <div className="card border-0 bg-secondary mb-4">
                    <div className="card-body">
                      <h3 className="h6 fw-normal pt-1">Amenities</h3>
                      <ul className="list-unstyled row row-cols-md-1 row-cols-1 gy-2 mb-0 text-nowrap">
                        {state?.features.map((item: any, idx: number) => (
                          <li className="" key={idx}>
                            <i className="fi-wifi mt-n1 me-2 fs-sm align-middle"></i>
                            {capitalize(item)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {/* <div className="card border-0 bg-secondary mb-4">
                <div className="card-body">
                  <h5>Not included in rent</h5>
                  <ul className="list-unstyled row row-cols-md-2 row-cols-1 gy-2 mb-0 text-nowrap">
                    <li className="col">
                      <i className="fi-swimming-pool mt-n1 me-2 fs-lg align-middle"></i>
                      Swimming pool
                    </li>
                    <li className="col">
                      <i className="fi-cafe mt-n1 me-2 fs-lg align-middle"></i>
                      Restaurant
                    </li>
                    <li className="col">
                      <i className="fi-spa mt-n1 me-2 fs-lg align-middle"></i>
                      Spa lounge
                    </li>
                    <li className="col">
                      <i className="fi-cocktail mt-n1 me-2 fs-lg align-middle"></i>
                      Bar
                    </li>
                  </ul>
                </div>
              </div> */}
                <ul className="d-flex mb-4 list-unstyled fs-sm">
                  <li className="me-3 pe-3 border-end">
                    Published: <br />
                    {formatDate(state?.created_at)}
                  </li>
                  <li className=" border-end">
                    Property Number: <br />
                    681013232
                  </li>
                  {/* <li className="me-3 pe-3">
                  Views: <b>48</b>
                </li> */}
                </ul>
              </div>
            </aside>
          </div>
        </section>
      ) : (
        <SinglePropertySkeleton />
      )}
      {/* <RecentProperty /> */}
    </AppComponent>
  );
};

const mapStateToProps = (state: any) => ({
  search: state.metric.search,
  selected: state.metric.selectedMetrics,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetch: (param: any) => dispatch(getSingleProperty(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleProperty);
