import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "../../../assets/img/avatar.png";
import PAGES_URL from "../../../routes";
import {
  GrBarChart,
  GrCreditCard,
  GrLock,
  GrMultiple,
  GrPowerShutdown,
  GrUser,
} from "react-icons/gr";
import Resizer from "react-image-file-resizer";
import { updateAvatar } from "../../../redux/actions/user.action";

const Menu = ({ user, upload }: any) => {
  const [image, setImage] = useState<any | Blob>(user?.avatar);

  const handleImageChange = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const image = await resizeFile(e.target.files[0]);
      const formData = { name: user?.fullname, file: e.target.files[0] };
      upload(formData).then((avatar: any) => {
        setImage(image);
      });
    }
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        48,
        48,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        48,
        48
      );
    });

  return (
    <aside className="col-lg-4 col-md-5 pe-xl-4 mb-5 position-sticky sticky-top">
      <div className="card card-body border-0 shadow-sm pb-1 me-lg-1 ">
        <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4">
          <div className="position-relative flex-shrink-0 uploader">
            <img
              className="rounded-circle "
              src={image || Avatar}
              alt={user?.fullname}
              style={{ width: 48, height: 48 }}
            />
            <button
              className="btn_img btn btn-icon btn-light  rounded-circle shadow-sm position-absolute end-0 bottom-0"
              style={{ width: "20px", height: "20px" }}
              type="button"
              data-bs-toggle="tooltip"
              title="Change image"
            >
              <i className="fi-pencil " style={{ fontSize: "10px" }}></i>
            </button>
            <input
              onChange={handleImageChange}
              type="file"
              name="myImage"
              accept="image/png, image/gif, image/jpeg"
            />
          </div>

          <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3">
            <h2 className="fs-lg mb-0">{user.fullname}</h2>

            <div className="d-flex ">
              <span className="fs-sm fw-normal p-0 ">
                <i className="fi fi-globe opacity-60 me-1"></i> United Kingdom
              </span>
            </div>
            <ul className="list-unstyled fs-sm mt-3 mb-0">
              <li>
                <Link className="nav-link fw-normal p-0" to="#">
                  <i className="fi fi-user opacity-60 me-2"></i>
                  {user.genders?.name ? user.genders.name : "Not Specified"}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link fw-normal p-0"
                  to={`tel:${user.phone}`}
                >
                  <i className="fi-phone opacity-60 me-2"></i>
                  {user.phone ? user.phone : "Not Specified"}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link fw-normal p-0"
                  to={`mailto:${user.email}`}
                >
                  <i className="fi-mail opacity-60 me-2"></i>
                  {user.email}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <Link
          className="btn btn-outline-secondary d-block d-md-none w-100 mb-3"
          to="#account-nav"
          data-bs-toggle="collapse"
        >
          <i className="fi-align-justify me-2"></i>Menu
        </Link>
        <div className="collapse d-md-block mt-3" id="account-nav">
          <div className="card-nav">
            <Link className="card-nav-link " to={PAGES_URL.Profile}>
              <GrBarChart size={20} className="me-2" /> Investment Preferences
            </Link>
            <Link className="card-nav-link " to={PAGES_URL.ProfileUpdate}>
              <GrUser size={20} className="me-2" /> Personal Details
            </Link>
            <Link className="card-nav-link" to={PAGES_URL.ProfilePreference}>
              <GrMultiple size={20} className="me-2" /> My Metric Base
            </Link>
            <Link className="card-nav-link" to={PAGES_URL.ProfilePreference}>
              <GrCreditCard size={20} className="me-2" /> Subscription
            </Link>
            <Link className="card-nav-link" to={PAGES_URL.PasswordUpdate}>
              <GrLock size={20} className="me-2" /> Password &amp; Security
            </Link>
            <Link className="card-nav-link" to={"#"}>
              <GrPowerShutdown size={20} className="me-2" /> Sign Out
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
};
const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  upload: async (formData: any) => dispatch(updateAvatar(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
