import React from "react";
import { connect } from "react-redux";
import { splitString } from "../../../helpers/String";
import { BsXCircleFill } from "react-icons/bs";

type Props = {
  data: any;
  setState?: any;
  selected: any;
  state?: any;
};

const MetricItem = ({ data, setState, selected, state }: Props) => {
  const handlePriorityChange = (e: any) => {
    let updateList = [...state];
    const { label, value, name, lead } = e.target.dataset;

    let current = updateList.some((e) => e.name === name);

    if (current) {
      let indexOf = updateList.findIndex((el: any) => el.name === name);
      updateList[indexOf]["label"] = label;
      updateList[indexOf]["lead"] = lead;
    } else {
      updateList = [...state, { name, label, value, lead }];
    }
    setState(updateList);
  };

  const removeMetric = (index: any) => {
    let arr = [...state];
    let arrIndex = arr.findIndex((val: any, inde) => index === inde);
    arr.splice(arrIndex, 1);
    setState(arr);
  };

  let checked = state.findIndex((el: any) => el.name === data.name);
  return (
    <div
      className=" mt-4 mb-4 mb-sm-3 me-3 dropdown"
      style={{ width: "300px" }}
    >
      <div
        className="icon-box card flex-row align-items-center justify-content-start flex-shrink-0  border-0 bg-secondary  py-3 ps-4 "
        data-bs-toggle="dropdown"
      >
        <div
          style={{ width: "100px" }}
          className={`icon-box-media ${
            !selected.some((el: any) => el.name === data.name)
              ? "bg-light text-primary"
              : "bg-primary text-white"
          }   fs-sm `}
        >
          {!selected.some((el: any) => el.name === data.name) ? (
            <i className="fi-dots-horisontal fs-sm"></i>
          ) : (
            <>{state[checked]?.lead}</>
          )}
        </div>

        <h3 className="icon-box-title fs-lg ps-3 pe-2 fw-normal mb-0">
          {/* <BsHash className="fw-bolder fs-5 text-primary" /> */}
          {splitString(data.name)}
        </h3>
        {selected.some((el: any) => el.name === data.name) && (
          <button
            className="ms-auto border-0 bg-transparent text-dark"
            onClick={() => removeMetric(checked)}
          >
            <BsXCircleFill className="me-4" />
          </button>
        )}
        <div className="dropdown-menu dropdown-menu-sm-end my-1">
          <button
            className="dropdown-item fs-sm fw-normal w-100"
            data-name={data.name}
            data-value={0}
            data-label={"High"}
            data-lead={data.lead[0]}
            onClick={handlePriorityChange}
          >
            <i className="fi-star fs-base me-2"></i>
            {data?.lead[0]}
          </button>
          <button
            data-name={data.name}
            data-value={0}
            data-label={"Low"}
            data-lead={data.lead[1]}
            className="dropdown-item fw-normal w-100"
            onClick={handlePriorityChange}
          >
            <i className="fi-star fs-base me-2"></i>
            {data?.lead[1]}
          </button>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state: any) => ({
  selected: state.metric.selectedMetrics,
});

export default connect(MapStateToProps, null)(MetricItem);
