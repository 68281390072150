/** @format */

import { SET_MATCHED_MATES } from "./types";

const initialState = {
  matched: null,
};

type Props = { type: string; payload?: any }; // Define the Action type

export default function mateReducer(state = initialState, action: Props) {
  switch (action.type) {
    case SET_MATCHED_MATES:
      return {
        ...state,
        matched: action.payload,
      };

    default:
      return state;
  }
}
