import { useEffect, useState } from "react";
import Modal from "../../../components/forms/Modal";
import MultiRangeSlider from "../../../components/forms/MultiRangeSlider";
import { connect } from "react-redux";
import { filter } from "../../../helpers/Filter";
import { setFilterOptions } from "../../../redux/actions/property";
import { BsPlusCircleDotted } from "react-icons/bs";
import { get_metrics_data } from "../../../helpers/Metrics";

type Props = {
  setFilteredData: Function;
  data: any;
  setFilters: Function;
  filters: any;
  search: any;
  selected: any;
};

const AdvancedFilter = ({
  setFilters,
  filters,
  setFilteredData,
  data,
  search,
  selected,
}: Props) => {
  const [state, setState] = useState<any>(filters);
  const [count, setCount] = useState(data?.length || 0);
  const [records, setRecords] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e: any) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const { checked } = e.target;
      let updatedList = state?.[name] ? [...state[name]] : [];
      if (checked) {
        updatedList = [...updatedList, value];
      } else {
        updatedList.splice(updatedList.indexOf(value), 1);
      }
      setState({ ...state, [name]: updatedList });
    } else {
      if (value === "reset" && state[name]) {
        delete state[name];
        setState({ ...state });
      } else {
        setState((prev: any) => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleOnRange = (e: any) => {
    setState((prev: any) => ({ ...prev, ...e }));
  };

  useEffect(() => {
    filter(state, data).then((filteredData: any) => {
      setFilteredData(filteredData);
      setCount(filteredData?.length || 0);
      setRecords(filteredData);
    });
  }, [data, setFilteredData, state]);

  const handleOnSubmit = async () => {
    setLoading(true);
    if (search || selected.length > 0) {
      get_metrics_data(
        search || { property_type: selected[0].property_type },
        selected,
        records
      ).then((data: any) => {
        setFilteredData(data);
        setCount(data?.length || 0);
        document.getElementById("close")?.click();
        setLoading(false);
      });
    }
    setFilters(state);
  };

  return (
    <Modal
      buttonLabel="Apply Filters"
      submit={handleOnSubmit}
      title={"Advanced Filters"}
      count={count}
      loading={loading}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="offcanvas-body py-lg-4">
              <button
                type="button"
                className=" border-0 pt-4 px-3  w-100"
                data-bs-toggle="collapse"
                data-bs-target="#PropertyFilters"
                aria-expanded="false"
                aria-controls="PropertyFilters"
              >
                <div className="d-flex">
                  <p>Property Filters</p>
                  <BsPlusCircleDotted className="ms-auto" />
                </div>
              </button>

              <div className="collapse mt-3" id="PropertyFilters">
                <div className="card card-body">
                  <div className="mb-4">
                    <h3 className="h6 fs-sm">Property type</h3>
                    <div
                      className="overflow-auto"
                      data-simplebar
                      data-simplebar-auto-hide="false"
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flat"
                          name="property_type"
                          value={"F"}
                          onChange={handleOnChange}
                          checked={state?.property_type?.includes("F")}
                        />
                        <label
                          className="form-check-label fs-sm"
                          htmlFor="flat"
                        >
                          Flat
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="terraced"
                          name="property_type"
                          value={"T"}
                          onChange={handleOnChange}
                          checked={state?.property_type?.includes("T")}
                        />
                        <label
                          className="form-check-label fs-sm"
                          htmlFor="terraced"
                        >
                          Terraced
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="detached"
                          name="property_type"
                          value={"D"}
                          onChange={handleOnChange}
                          checked={state?.property_type?.includes("D")}
                        />
                        <label
                          className="form-check-label fs-sm"
                          htmlFor="detached"
                        >
                          Detached
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="semidetached"
                          name="property_type"
                          value={"S"}
                          onChange={handleOnChange}
                          checked={state?.property_type?.includes("S")}
                        />
                        <label
                          className="form-check-label fs-sm"
                          htmlFor="semidetached"
                        >
                          Semi Detached
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label className="d-block fs-sm mb-1">Price</label>
                    <div className="mb-4">
                      <MultiRangeSlider
                        name="price"
                        onChange={handleOnRange}
                        data={data}
                        // min={rangeValues(records, "price").min}
                        // max={rangeValues(records, "price").max}
                        frontLabel="£"
                        step={1}
                      />
                    </div>
                  </div>
                  <div className="d-lg-flex">
                    <div className="pb-4 mb-2 ">
                      <label className="d-block fs-sm mb-1">Bedrooms</label>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Choose number of bedrooms"
                      >
                        <input
                          className="btn-check"
                          type="radio"
                          id="studio"
                          name="bedrooms"
                          value={0}
                          onClick={handleOnChange}
                          checked={state?.bedrooms === "0"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="studio"
                        >
                          Studio
                        </label>
                        <input
                          className="btn-check "
                          type="radio"
                          id="bedrooms-1"
                          value={1}
                          name="bedrooms"
                          onClick={handleOnChange}
                          checked={state?.bedrooms === "1"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bedrooms-1"
                        >
                          1
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bedrooms-2"
                          name="bedrooms"
                          value={2}
                          onClick={handleOnChange}
                          checked={state?.bedrooms === "2"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bedrooms-2"
                        >
                          2
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bedrooms-3"
                          name="bedrooms"
                          value={3}
                          onClick={handleOnChange}
                          checked={state?.bedrooms === "3"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bedrooms-3"
                        >
                          3
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bedrooms-4"
                          name="bedrooms"
                          value={4}
                          onClick={handleOnChange}
                          checked={state?.bedrooms === "4"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bedrooms-4"
                        >
                          4+
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bedrooms-reset"
                          name="bedrooms"
                          value={"reset"}
                          onChange={handleOnChange}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bedrooms-reset"
                        >
                          All
                        </label>
                      </div>
                    </div>
                    <div className="pb-4 mb-2 ms-lg-4">
                      <label className="d-block fs-sm mb-1">Bathrooms</label>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Choose number of bathrooms"
                      >
                        <input
                          className="btn-check"
                          type="radio"
                          id="bath-studio"
                          name="bathrooms"
                          value={0}
                          onClick={handleOnChange}
                          checked={state?.bathrooms === "0"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bath-studio"
                        >
                          Studio
                        </label>
                        <input
                          className="btn-check "
                          type="radio"
                          id="bathrooms-1"
                          value={1}
                          name="bathrooms"
                          onClick={handleOnChange}
                          checked={state?.bathrooms === "1"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bathrooms-1"
                        >
                          1
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bathrooms-2"
                          name="bathrooms"
                          value={2}
                          onClick={handleOnChange}
                          checked={state?.bathrooms === "2"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bathrooms-2"
                        >
                          2
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bathrooms-3"
                          name="bathrooms"
                          value={3}
                          onClick={handleOnChange}
                          checked={state?.bathrooms === "3"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bathrooms-3"
                        >
                          3
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bathrooms-4"
                          name="bathrooms"
                          value={4}
                          onClick={handleOnChange}
                          checked={state?.bathrooms === "4"}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bathrooms-4"
                        >
                          4+
                        </label>
                        <input
                          className="btn-check"
                          type="radio"
                          id="bathrooms-reset"
                          name="bathrooms"
                          value={"reset"}
                          onChange={handleOnChange}
                        />
                        <label
                          className="btn btn-outline-secondary fw-normal"
                          htmlFor="bathrooms-reset"
                        >
                          All
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="mb-4 me-3 me-lg-5">
                      <h3 className="h6 fs-sm">Features</h3>

                      <div className=" ">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="parking"
                            name="features"
                            value={"parking"}
                            onChange={handleOnChange}
                            checked={state?.features?.includes("parking")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="parking"
                          >
                            Parking
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="garden"
                            name="features"
                            value={"garden"}
                            onChange={handleOnChange}
                            checked={state?.features?.includes("garden")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="garden"
                          >
                            Garden
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="newbuild"
                            name="features"
                            value={"new build"}
                            onChange={handleOnChange}
                            checked={state?.features?.includes("new build")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="newbuild"
                          >
                            New build
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="basement"
                            name="features"
                            value={"basement"}
                            onChange={handleOnChange}
                            checked={state?.features?.includes("basement")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="basement"
                          >
                            Basement
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="lift"
                            name="features"
                            value={"lift"}
                            onChange={handleOnChange}
                            checked={state?.features?.includes("lift")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="lift"
                          >
                            Lift
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <h3 className="h6 fs-sm">Tenure</h3>
                      <div
                        className=""
                        data-simplebar
                        data-simplebar-auto-hide="false"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="parking"
                            name="tenure"
                            value={"freehold"}
                            onChange={handleOnChange}
                            checked={state?.tenure?.includes("freehold")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="air-condition"
                          >
                            Freehold
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="parking"
                            name="tenure"
                            value={"leasehold"}
                            onChange={handleOnChange}
                            checked={state?.tenure?.includes("leasehold")}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="air-condition"
                          >
                            Leasehold
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="parking"
                            name="tenure"
                            value={"share of freehold"}
                            onChange={handleOnChange}
                            checked={state?.tenure?.includes(
                              "share of freehold"
                            )}
                          />
                          <label
                            className="form-check-label fs-sm"
                            htmlFor="air-condition"
                          >
                            Share of Freehold
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                className=" border-0 pt-4 px-3  mt-5 pt-3  w-100"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <div className="d-flex">
                  <p>Area Filters</p>
                  <BsPlusCircleDotted className="ms-auto" />
                </div>
              </button>

              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <div className="mb-5 ms-lg-4">
                    <h3 className=" h6 fs-sm">Proximities</h3>

                    <label className="d-block fs-sm mb-1">Green Area</label>
                    <MultiRangeSlider
                      name="green_areas"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />
                    <label className="d-block fs-sm  mt-5 pt-3 pt-3  mb-1">
                      Primary School
                    </label>

                    <MultiRangeSlider
                      name="primary_proximity"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />

                    <label className="d-block fs-sm  mt-5 pt-3 pt-3  mb-1">
                      Secondary School
                    </label>

                    <MultiRangeSlider
                      name="secondary_proximity"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />

                    <label className="d-block fs-sm  mt-5 pt-3 pt-3  mb-1">
                      Private School
                    </label>

                    <MultiRangeSlider
                      name="private_proximity"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />

                    <label className="d-block fs-sm   mt-5 pt-3 pt-3  mb-1">
                      Supermarket
                    </label>

                    <MultiRangeSlider
                      name="supermarket_proximity"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />

                    <label className="d-block fs-sm  mt-5 pt-3  mb-1">
                      Station
                    </label>

                    <MultiRangeSlider
                      name="station_proximity"
                      onChange={handleOnRange}
                      backLabel="km"
                      data={data}
                      step={0.001}
                    />
                  </div>
                  <div className="pb-4 ms-lg-4  mt-5 mb-2">
                    <h3 className="h6 fs-sm">Essentials</h3>
                    <div className="input-group w-lg-50">
                      <select
                        onChange={handleOnChange}
                        name="tube_station"
                        className="form-control"
                      >
                        <option value="">List of Tube Lines</option>
                        <option
                          value="Bakerloo"
                          defaultChecked={state?.tube_station === "Bakerloo"}
                        >
                          Bakerloo
                        </option>
                        <option
                          value="Central"
                          defaultChecked={state?.tube_station === "Central"}
                        >
                          Central
                        </option>
                        <option
                          value="Circle"
                          defaultChecked={state?.tube_station === "Circle"}
                        >
                          Circle
                        </option>
                        <option
                          value="district"
                          defaultChecked={state?.tube_station === "district"}
                        >
                          district
                        </option>
                        <option
                          value="Elizabeth"
                          defaultChecked={state?.tube_station === "Elizabeth"}
                        >
                          Elizabeth
                        </option>
                        <option
                          value="Hammersmith & City"
                          defaultChecked={
                            state?.tube_station === "Hammersmith & City"
                          }
                        >
                          Hammersmith & City
                        </option>
                        <option
                          value="Jubilee"
                          defaultChecked={state?.tube_station === "Jubilee"}
                        >
                          Jubilee
                        </option>
                        <option
                          value="Metropolitan"
                          defaultChecked={
                            state?.tube_station === "Metropolitan"
                          }
                        >
                          Metropolitan
                        </option>
                        <option
                          value="Northern"
                          defaultChecked={state?.tube_station === "Northern"}
                        >
                          Northern
                        </option>
                        <option
                          value="Piccadilly"
                          defaultChecked={state?.tube_station === "Piccadilly"}
                        >
                          Piccadilly
                        </option>
                        <option
                          value="Victoria"
                          defaultChecked={state?.tube_station === "Victoria"}
                        >
                          Victoria
                        </option>
                      </select>
                      <input
                        name="tube_station_time"
                        onChange={handleOnChange}
                        className="form-control"
                        type="text"
                        value={
                          state?.tube_station_time && state?.tube_station_time
                        }
                        placeholder="Enter Distance "
                      />
                    </div>
                    <div className="mt-5">
                      <label className="d-block fs-sm mb-1">
                        Educational Level
                      </label>

                      <select
                        onChange={handleOnChange}
                        name="educational_levels"
                        className="form-control"
                      >
                        <option value="">Choose Education Levels</option>
                        <option value="Very High">Very High</option>
                        <option value="High">High</option>
                        <option value="Average">Average</option>
                        <option value="Low">Low</option>
                        <option value="Very Low">Very Low</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="d-block fs-sm  mt-5 mb-2">
                        Crime Rate (per 1000)
                      </label>

                      <MultiRangeSlider
                        name="crime"
                        onChange={handleOnRange}
                        backLabel="cr"
                        data={data}
                        step={0.1}
                      />
                    </div>

                    <div className=" mt-5 pt-3">
                      <label className="d-block fs-sm mb-2">
                        Broadband Speed
                      </label>
                      <MultiRangeSlider
                        name="broadband_speed"
                        onChange={handleOnRange}
                        data={data}
                        backLabel="Mbps"
                        step={0.1}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {search?.type === "Sale" && (
                <>
                  <button
                    type="button"
                    className=" border-0 pt-4 px-3  mt-5 pt-3  w-100"
                    data-bs-toggle="collapse"
                    data-bs-target="#investments"
                    aria-expanded="false"
                    aria-controls="investments"
                  >
                    <div className="d-flex">
                      <p>Investments Filters</p>
                      <BsPlusCircleDotted className="ms-auto" />
                    </div>
                  </button>

                  <div className="collapse" id="investments">
                    <div className="card card-body">
                      <div className="d-lg-flex flex-column">
                        <div className="pb-4 ms-lg-4 mb-2">
                          <h3 className="h6 fs-sm">Yields</h3>
                          {data[0].rental_affordability != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Rental Value
                              </label>
                              <MultiRangeSlider
                                data={data}
                                name="rental_affordability"
                                onChange={handleOnRange}
                                frontLabel="£"
                              />
                            </div>
                          )}
                          {data[0].gross_yields != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Gross Yields
                              </label>
                              <MultiRangeSlider
                                name="gross_yields"
                                onChange={handleOnRange}
                                data={data}
                                backLabel="%"
                                step={0.1}
                              />
                            </div>
                          )}
                        </div>
                        <div className="pb-4 ms-lg-4 mb-2">
                          <h3 className="h6 fs-sm">Affordability</h3>
                          {data[0].rental_affordability != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Rental Affordability
                              </label>
                              <MultiRangeSlider
                                name="rental_affordability"
                                onChange={handleOnRange}
                                data={data}
                                frontLabel="£"
                                step={0.1}
                              />
                            </div>
                          )}
                          {data[0].mortgage_affordability != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-2">
                                Mortgage Affordability
                              </label>
                              <MultiRangeSlider
                                name="mortgage_affordability"
                                onChange={handleOnRange}
                                data={data}
                                frontLabel="£"
                                step={0.1}
                              />
                            </div>
                          )}
                          {data[0].mean_income != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Mean Income
                              </label>
                              <MultiRangeSlider
                                name="mean_income"
                                onChange={handleOnRange}
                                data={data}
                                frontLabel="£"
                                step={0.1}
                              />
                            </div>
                          )}
                          {data[0].area_leverage != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Area Leverage
                              </label>
                              <MultiRangeSlider
                                name="area_leverage"
                                onChange={handleOnRange}
                                data={data}
                                frontLabel="£"
                                step={0.1}
                              />
                            </div>
                          )}
                          {data[0].debt_per_building != null && (
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Debt per Building
                              </label>
                              <MultiRangeSlider
                                name="debt_per_building"
                                onChange={handleOnRange}
                                data={data}
                                backLabel=" days"
                                step={0.1}
                              />
                            </div>
                          )}
                        </div>
                        {data[0].listing_age != null && (
                          <div className="pb-4 ms-lg-4 mb-2">
                            <h3 className="h6 fs-sm">Seller Motivation</h3>
                            <div className="mb-5  ">
                              <label className="d-block fs-sm mb-1">
                                Listing Age
                              </label>
                              <MultiRangeSlider
                                name="listing_age"
                                onChange={handleOnRange}
                                data={data}
                                backLabel=" days"
                                step={0.1}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const MapStateToProps = (state: any) => ({
  data: state.properties.propertyData,
  filters: state.properties.filters,
  search: state.metric.search,
  selected: state.metric.selectedMetrics,
});
const MapDispatchToProps = (dispatch: any) => ({
  setFilters: (options: any) => dispatch(setFilterOptions(options)),
});

export default connect(MapStateToProps, MapDispatchToProps)(AdvancedFilter);
