import React, { useState } from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "../../routes";
import Menu from "./components/Menu";
import AppComponent from "../../components/layouts/AppComponent";
import { connect } from "react-redux";
import Button from "../../components/forms/Buttons";
import { updateUserProfile } from "../../redux/actions/user.action";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { toast } from "react-toastify";

// interface UserInterface {
//   fullname: string;
//   phone: string;
//   genders_id: string;
//   bio: string;
//   address: string;
// }

type Props = {
  user: any;
  updateUser: Function;
  countries: Array<[]>;
};

const ProfileUpdate = ({ user, updateUser, countries }: Props) => {
  const [state, setState] = useState<any>(user);
  const [loading, setLoading] = useState<any | boolean>(false);
  const [progress, setProgress] = useState<any>(0);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = (e: any) => {
    setLoading(true);
    setProgress(30);
    updateUser(state).then((response: any) => {
      setLoading(false);
      toast.success("Saved");
      setProgress(100);
    });
  };

  return (
    <AppComponent dark>
      <LoaderComponent
        topStyle={"100px"}
        progress={progress}
        loading={loading}
      />
      <section className="section mt-3">
        <div className="container  pb-lg-4 mb-sm-2">
          <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={PAGES_URL.Landing}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Account</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Overview
              </li>
            </ol>
          </nav>
          <div className="row">
            <Menu />
            <div className="col-lg-8 col-md-7 mb-5">
              <h1 className="h2 mb-1">Personal Details</h1>
              <div className="mb-4 fw-bold">
                These details describe my characteristics as a person in
                property.
              </div>

              <div className="border rounded-3 p-3 mb-4" id="personal-info">
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Full Name</label>
                      <div id="name-value">{state.fullname}</div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#name-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="name-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control mt-3"
                      type="text"
                      name="fullname"
                      onChange={handleOnChange}
                      data-bs-binded-element="#name-value"
                      data-bs-unset-value="Not specified"
                      placeholder="Enter a valid fullname"
                      value={state.fullname}
                    />
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Email</label>
                      <div id="email-value">{state.email}</div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#email-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse "
                    id="email-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <div className="d-flex flex-column">
                      <input
                        className="form-control mt-3 mb-1"
                        type="email"
                        disabled={true}
                        data-bs-binded-element="#email-value"
                        data-bs-unset-value="Not specified"
                        value={state.email}
                      />
                      <Link
                        className=" fw-bold text-decoration-none ms-auto"
                        style={{ fontSize: "12px" }}
                        to={"#"}
                      >
                        Contact Support
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Phone Number</label>
                      <div id="phone-value">
                        {state.phone ? state.phone : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#phone-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="phone-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <input
                      className="form-control mt-3"
                      type="text"
                      name="phone"
                      onChange={handleOnChange}
                      data-bs-binded-element="#phone-value"
                      data-bs-unset-value={
                        state.phone ? state.phone : "Not Specified"
                      }
                      placeholder="Please enter a valid phone number"
                      value={state.phone && state.phone}
                    />
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Sex</label>
                      <div id="gender-value">
                        {state.genders ? state.genders.name : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#gender-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="gender-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <select
                      onChange={handleOnChange}
                      className="form-control"
                      name="genders_id"
                      id=""
                    >
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      {!state.genders_id && (
                        <option
                          selected={state.genders_id ? state.genders_id : true}
                          defaultChecked={
                            state.genders_id
                              ? state.genders_id
                              : "Not Specified"
                          }
                          defaultValue={
                            state.genders_id
                              ? state.genders_id
                              : "Not Specified"
                          }
                        >
                          Please Choose Gender
                        </option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Country</label>
                      <div id="country-value">
                        {state.countries?.name || "Not specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#country-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="country-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <select
                      name="countries_id"
                      id=""
                      className="form-control"
                      onChange={handleOnChange}
                    >
                      {!state.countries_id && <option>Choose a country</option>}
                      {countries?.map((el: any) => (
                        <option
                          value={el.id}
                          defaultChecked={state.countries_id === el.id}
                        >
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Personal Bio</label>
                      <div id="bio-value">
                        {user.bio ? user.bio : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#bio-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="bio-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <textarea
                      className="form-control mt-3"
                      name="bio"
                      onChange={handleOnChange}
                      value={state.bio}
                      placeholder="Enter short personal bio"
                    >
                      {state.bio}
                    </textarea>
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">
                        Areas of Interest
                      </label>
                      <div id="area_of_interest-value">
                        {user.area_of_interest
                          ? user.area_of_interest
                          : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#area_of_interest-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="area_of_interest-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <textarea
                      className="form-control mt-3"
                      name="area_of_interest"
                      onChange={handleOnChange}
                      value={state.area_of_interest}
                      placeholder="Express your area of interets"
                    >
                      {state.area_of_interets}
                    </textarea>
                  </div>
                </div>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">
                        Goals & Objectives
                      </label>
                      <div id="goals-value">
                        {user.goals ? user.goals : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#goals-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="goals-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <textarea
                      className="form-control mt-3"
                      name="goals"
                      onChange={handleOnChange}
                      value={state.goals}
                      placeholder="What are your goals and objectives"
                    >
                      {state.goals}
                    </textarea>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="pe-2">
                      <label className="form-label fw-bold">Address</label>
                      <div id="address-value">
                        {" "}
                        {state.address ? state.address : "Not Specified"}
                      </div>
                    </div>
                    <div data-bs-toggle="tooltip" title="Edit">
                      <a
                        className="nav-link py-0"
                        href="#address-collapse"
                        data-bs-toggle="collapse"
                      >
                        <i className="fi-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id="address-collapse"
                    data-bs-parent="#personal-info"
                  >
                    <textarea
                      className="form-control mt-3"
                      name="address"
                      onChange={handleOnChange}
                      value={state.address}
                      placeholder="A valid address"
                    >
                      {state.address}
                    </textarea>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between border-top mt-4 pt-4 pb-1">
                <Button
                  loading={loading}
                  onClick={handleFormSubmit}
                  className="btn btn-primary px-3 px-sm-4"
                  type="button"
                >
                  Save changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

const MapStateToProps = (state: any) => ({
  user: state.user.user,
  countries: state.user.countries,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (data: any) => dispatch(updateUserProfile(data)),
});

export default connect(MapStateToProps, mapDispatchToProps)(ProfileUpdate);
