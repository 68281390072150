import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MetricsMateSkeleton = () => {
  return (
    <div className="container content-overlay mt-3 mb-md-4 ">
      <nav className="mb-3 mb-md-4 pt-md-3" aria-label="Breadcrumb">
        <ol className="breadcrumb breadcrumb-dark">
          <li className="breadcrumb-item">
            <Skeleton width={80} />
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Skeleton width={160} />
          </li>
        </ol>
      </nav>
      <div className="bg-light  rounded-3 mb-2">
        <div className="d-flex align-items-center justify-content-between py-4 mt-3 mb-2">
          <div>
            <h1 className="h3 mb-2">
              {" "}
              <Skeleton height={40} width={300} />
            </h1>
            <p className="fw-bold text-dark">
              <Skeleton width={100} />
            </p>
          </div>
        </div>
        <div className="row g-2 g-md-4">
          {Array(9)
            .fill("Dummy")
            .map((el: any, index: any) => (
              <div className="col-md-6 col-lg-4" key={index}>
                <div className="card bg-secondary card-hover h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <Skeleton
                        circle={true}
                        containerClassName="rounded-circle me-3"
                        width={50}
                        height={50}
                      />
                      <div className="fs-sm text-dark opacity-80 px-1">
                        <span className="d-block fs-sm fw-bold">
                          <Skeleton width={200} />
                        </span>
                        <span className="fs-sm fw-normal">
                          <Skeleton width={200} />
                        </span>
                      </div>
                      <span className=" rounded-pill fs-sm ms-auto">
                        <Skeleton width={30} />
                      </span>
                    </div>
                    <h3 className="h6 card-title mb-2">
                      <Skeleton width={200} />
                    </h3>
                    <p className="fs-sm mb-0 fw-normal">
                      <Skeleton height={200} />
                    </p>
                  </div>

                  <div className="card-footer ">
                    <div className="">
                      <div className="row">
                        {Array(3).map((lt: any) => (
                          <Skeleton width={50} height={10} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MetricsMateSkeleton;
