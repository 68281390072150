/** @format */

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PAGES_URL from "./index";

const Protected = ({ isAuthenticated, user }: any) => {
  return isAuthenticated && user ? (
    <Outlet />
  ) : (
    <Navigate to={PAGES_URL.Login} />
  );
};

const mapStatetoProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user.user,
});

export default connect(mapStatetoProps, null)(Protected);
