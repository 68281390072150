import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SinglePropertySkeleton = () => {
  return (
    <section className="container mt-3 ">
      <nav className="mb-3 pt-md-3" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Skeleton width={50} />
          </li>
          <li className="breadcrumb-item">
            <Skeleton width={50} />
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Skeleton width={200} />
          </li>
        </ol>
      </nav>
      <div className="row gy-5 pt-lg-2">
        <div className="col-lg-8">
          <div className="d-flex flex-column">
            <div className="order-lg-1 order-2">
              <div className="tns-carousel-wrapper">
                <Skeleton height={500} />
              </div>
              <div className="row">
                {Array(4)
                  .fill("Dummy")
                  .map((item: any, idx: number) => (
                    <Skeleton containerClassName="col-md-3 pe-1" height={150} />
                  ))}
              </div>
            </div>
            <div className="order-lg-2 order-1 mt-4">
              <h1 className="h2  fw-normal mb-1">
                <Skeleton height={50} />
              </h1>
              <p className="mb-2 pb-1 fs-lg text-dark fw-normal">
                <Skeleton width={300} />
              </p>
              <ul className="list-unstyled d-flex fs-sm">
                <li className="">
                  <Skeleton width={100} containerClassName="me-2" />
                </li>

                <li className="">
                  <Skeleton width={100} containerClassName="me-2" />
                </li>

                <li className="">
                  <Skeleton width={100} containerClassName="me-2" />
                </li>

                <li className="">
                  <Skeleton width={100} containerClassName="me-2" />
                </li>
              </ul>
            </div>
          </div>
          <h2 className="h6 fw-bold fs-lg ">
            <Skeleton width={150} />
          </h2>
          <p className="mb-4 pb-2 fw-normal">
            <Skeleton height={500} />
          </p>
          <div className="my-4 row">
            <h2 className="h6 fw-bold fs-lg">
              {" "}
              <Skeleton width={100} />
            </h2>
            {Array(4)
              .fill("Dummy")
              .map((item: any, idx: number) => (
                <div className="col">
                  <Skeleton height={200} />
                </div>
              ))}
          </div>
          <hr />
          <h2 className="h6 fw-bold fs-lg mt-3">
            {" "}
            <Skeleton />
          </h2>
          <div className="row">
            <aside className="col-lg-5 col-md-4 mb-5">
              <div className="pe-lg-3">
                <Skeleton />
                <h2 className="h5 text-center text-md-start mb-1 fw-normal">
                  <Skeleton />
                </h2>
                <p className="text-center text-md-start mb-2 fw-normal pb-1">
                  <Skeleton />
                </p>

                <div className="text-center text-md-start pt-md-2 mt-2">
                  <Skeleton />
                </div>
              </div>
            </aside>
          </div>
        </div>
        <aside className="col-lg-4" style={{ marginTop: "-6rem" }}>
          <div className="sticky-top pt-5 ">
            <div className="d-flex align-items-center  pt-5 mb-3">
              <Skeleton height={30} width={200} />
            </div>
          </div>

          <h3 className="h5 mb-2">
            {" "}
            <Skeleton height={30} />
          </h3>
          <h2 className="h2 fw-normal ">
            <Skeleton height={50} />
            <span className="d-inline-block ms-1 fs-base fw-normal text-body"></span>
          </h2>
          <div
            className=" mb-2"
            data-bs-toggle="collapse"
            data-bs-target="#cardCollapse3"
          >
            <Skeleton height={100} />
          </div>
          <div
            className=" mb-2"
            data-bs-toggle="collapse"
            data-bs-target="#cardCollapse3"
          >
            <Skeleton height={100} />
          </div>
          <div
            className=" mb-2"
            data-bs-toggle="collapse"
            data-bs-target="#cardCollapse3"
          >
            <Skeleton height={100} />
          </div>

          <div className="card border-0 bg-secondary mb-4">
            <div className="card-body">
              <h3 className="h6 fw-normal pt-1">
                <Skeleton />
              </h3>
              <ul className="list-unstyled row row-cols-md-1 row-cols-1 gy-2 mb-0 text-nowrap">
                {Array(6)
                  .fill("Dummy")
                  .map((item: any, idx: number) => (
                    <li className="" key={idx}>
                      <Skeleton width={200} />
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <ul className="d-flex mb-4 list-unstyled fs-sm">
            <li className="me-3 pe-3 border-end">
              <Skeleton width={80} />
            </li>
            <li className="me-3 pe-3 border-end">
              <Skeleton width={80} />
            </li>
            <li className="">
              <Skeleton width={80} />
            </li>
          </ul>
        </aside>
      </div>
    </section>
  );
};

export default SinglePropertySkeleton;
