import React from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "../routes";
import NotFoundImg from "../assets/img/404.png";

const NotFound = () => {
  return (
    <main className="page-wrapper">
      <section className="d-flex align-items-center position-relative min-vh-100 py-5">
        <span
          className="position-absolute top-50 start-50 translate-middle zindex-1 rounded-circle mt-sm-0 mt-n5"
          style={{
            width: "50vw",
            height: "50vw",
            backgroundColor: "rgba(83, 74, 117, 0.3)",
            filter: "blur(6.4vw)",
          }}
        ></span>
        <div className="container d-flex justify-content-center position-relative zindex-5 text-center">
          <div className="col-lg-8 col-md-10 col-12 px-0">
            <h1 className="display-1 mb-lg-5 mb-4 text-dark">Oops!</h1>
            <div
              className="ratio ratio-16x9 mx-auto mb-lg-5 mb-4 py-4"
              style={{ maxWidth: "300px;" }}
            >
              <img src={NotFoundImg} alt="" />
            </div>
            <p className="h2 mb-lg-5 mb-4 pb-2 text-dark">
              The requested page doesn’t exist
            </p>
            <Link
              className="btn btn-lg btn-primary w-sm-auto w-100 mb-3 me-sm-4"
              to={PAGES_URL.Landing}
            >
              Go to homepage
            </Link>
            <Link
              className="btn btn-lg btn-outline-dark w-sm-auto w-100 mb-3"
              to={PAGES_URL.ContactUs}
            >
              Contact us
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default NotFound;
