import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { connect } from "react-redux";
import {
  setMetricPage,
  setSelectedMetrics,
} from "../../../redux/actions/metric.action";
import MetricItem from "./MetricItem";
import Header from "./Header";

const defaultState = [
  { name: "price", lead: ["Highest", "Lowest"] },
  { name: "bathrooms", lead: ["Most", "Least"] },
  { name: "bedrooms", lead: ["Most", "Least"] },
  { name: "square_footage", lead: ["Highest", "Lowest"] },
];

const Property = ({ pageId, setPage, setMetric, search, selected }: any) => {
  const [state, setState] = useState<any | []>(selected);
  const prev = () => {
    setPage(1);
  };

  const next = () => {
    setPage(3);
  };

  useEffect(() => {
    setMetric(state);
  }, [setMetric, state]);
  return (
    <div className="container vh-100">
      <div className="row h-100">
        <Header
          progress={2}
          percent={40}
          title={`Property Metrics`}
          subtext="Select only the metrics that matter to you."
        />
        <section className="position-relative rounded-xxl-4 pt-md-3 zindex-5">
          <div className="container ">
            <h4 className="h4  fw-normal ">
              If all other things were equal, I would choose the property with
              the...
            </h4>
            <div className="d-flex  flex-column">
              {defaultState &&
                defaultState?.map((item: any) => (
                  <MetricItem data={item} state={state} setState={setState} />
                ))}
            </div>
          </div>
        </section>

        <div className="d-flex">
          <button
            onClick={prev}
            className="btn text-dark  "
            style={{ fontSize: "69px" }}
          >
            <BsArrowLeft size={40} className="" />{" "}
            <span className=" fw-normal ms-3">Search</span>
          </button>
          <button onClick={next} className="btn  text-dark ms-auto ">
            <span className=" fw-normal  me-3">Area </span>

            <BsArrowRight size={40} />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  selected: state.metric.selectedMetrics,
  pageId: state.metric.pageId,
});

const mapDispatchToProps = (dispatch: any) => ({
  setPage: (data: any) => dispatch(setMetricPage(data)),
  setMetric: (data: any) => dispatch(setSelectedMetrics(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
