import React, { useState } from "react";
import DraggableListItem from "./DraggableListItem";
import { splitString } from "../../helpers/String";
import { connect } from "react-redux";
import { setPropertyData } from "../../redux/actions/property";
import { BsXCircleFill } from "react-icons/bs";

type Props = {
  data: any;
  renderItemContent: Function;
  setMetric: Function;
  search: any;
  setPropertyData: Function;
};

const DraggableList = (props: Props) => {
  const [data, setdata] = useState<any>(props.data);

  const [dragStartIndex, setdragStartIndex] = useState<any>(null);

  // get index of draged item
  const onDragStart = (index: any) => {
    setdragStartIndex(index);
  };

  // update list when item dropped
  const onDrop = (dropIndex: any) => {
    // get draged item
    const dragItem = data[dragStartIndex];

    // delete draged item in list
    let list = [...data];
    list.splice(dragStartIndex, 1);
    let record;

    // update list
    if (dragStartIndex < dropIndex) {
      record = [
        ...list.slice(0, dropIndex - 1),
        dragItem,
        ...list.slice(dropIndex - 1, list.length),
      ];
      setdata(record);
    } else {
      record = [
        ...list.slice(0, dropIndex),
        dragItem,
        ...list.slice(dropIndex, list.length),
      ];
      setdata(record);
    }

    // console.log(props.search, record);

    props.setMetric(record);
  };

  let indexCount = 0;

  const removeMetric = (index: any) => {
    let arr = [...data];
    let arrIndex = arr.findIndex((val: any, inde) => index === inde);
    arr.splice(arrIndex, 1);
    setdata(arr);
    props.setMetric(arr);
  };

  return (
    <div className="d-flex flex-column ">
      <p className="fw-normal fs-lg">
        Just drag and drop your metrics to arrange them{" "}
      </p>
      <div className="col-lg-3">
        <p className="badge bg-secondary fw-bolder fs-sm">Highest Importance</p>
      </div>
      {data.map((item: any, index: any) => (
        <DraggableListItem
          key={index}
          index={index}
          draggable={true}
          onDragStart={(index: any) => onDragStart(index)}
          onDrop={(index: any) => onDrop(index)}
        >
          <div
            className="dropdown mb-2 mb-sm-3 me-3"
            style={{ width: "300px", cursor: "pointer" }}
          >
            <div className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 bg-secondary rounded-pill py-2 ps-2 pe-4">
              <div
                className={`icon-box-media bg-light text-primary rounded-circle fs-sm fw-bolder `}
              >
                {++indexCount}
              </div>
              <h3 className="icon-box-title fs-sm ps-1 pe-2 fw-normal py-2 mb-0">
                {item.lead} {splitString(item?.name)}
              </h3>
              <button
                onClick={() => removeMetric(index)}
                className="ms-auto click bg-transparent border-0"
              >
                <BsXCircleFill />
              </button>
            </div>
          </div>
        </DraggableListItem>
      ))}
      <DraggableListItem
        key={data.length}
        index={data.length}
        draggable={false}
        onDrop={(index: any) => onDrop(index)}
      />
      <div className="col-lg-3">
        <p className="badge  bg-secondary fw-bolder fs-sm">Lesser Importance</p>
      </div>
    </div>
  );
};

const DispatchToProps = (dispatch: any) => ({
  setPropertyData: (data: any) => dispatch(setPropertyData(data)),
});

export default connect(null, DispatchToProps)(DraggableList);
