/** @format */

import {
  CLEAR_USER_DATA,
  SET_COUNTRIES_LIST,
  SET_INVESTMENT,
  SET_USER,
  SET_USER_AVATAR,
} from "./types";

const initialState = {
  user: {},
  investment: null,
  countries: null,
};

type Props = { type: string; payload?: any }; // Define the Action type

export default function userReducer(state = initialState, action: Props) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_INVESTMENT:
      return {
        ...state,
        investment: action.payload,
      };
    case SET_USER_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
        },
      };
    case SET_COUNTRIES_LIST:
      return {
        ...state,
        countries: action.payload,
      };
    case CLEAR_USER_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
