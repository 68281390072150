import supabase from "../../lib/supabase/client";

export const fetchMatchedMates = () => async (dispatch: any) => {
  const data = await getListOfMatchMates();

  return data;
};

const getListOfMatchMates = async () => {
  const { data } = await supabase.from("profiles").select(
    "*, metrics(*), investments(*)",
  ).neq("id", (await supabase.auth.getUser()).data.user?.id);
  return data;
};

export const fetchSingleMatchMates = (user: any) => async (dispatch: any) => {
  const { data } = await supabase.from("profiles").select(
    "*, investments(*), metrics(*)",
  ).eq("id", user.id).single();
  return data;
  // dispatch({ type: SET_MATCHED_MATES, payload: data });
};
