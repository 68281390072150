/** @format */
import React from "react";
import { Route, Routes } from "react-router-dom";
import PAGES_URL from "./routes/index";
import {
  ContactUs,
  Landing,
  Login,
  Metric,
  PasswordUpdate,
  ProfileOverview,
  Properties,
  SignUp,
  SingleProperty,
  ProfileUpdate,
  SingleMates,
  MatchedMates,
  ProfilePreference,
  HowItWorks,
  MySubscription,
} from "./pages";
import Protected from "./routes/Protected";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route path={PAGES_URL.Landing} element={<Landing />} />
      <Route path={PAGES_URL.ContactUs} element={<ContactUs />} />
      <Route path={PAGES_URL.Login} element={<Login />} />
      <Route path={PAGES_URL.SignUp} element={<SignUp />} />

      <Route element={<Protected />}>
        <Route path={PAGES_URL.Profile} element={<ProfileOverview />} />
        <Route path={PAGES_URL.ProfileUpdate} element={<ProfileUpdate />} />
        <Route path={PAGES_URL.PasswordUpdate} element={<PasswordUpdate />} />
        <Route
          path={PAGES_URL.ProfilePreference}
          element={<ProfilePreference />}
        />

        <Route path={`${PAGES_URL.Property}`} element={<Properties />} />
        <Route
          path={`${PAGES_URL.Subscription}`}
          element={<MySubscription />}
        />

        <Route
          path={`${PAGES_URL.SingleMates}/:id`}
          element={<SingleMates />}
        />
        <Route
          path={`${PAGES_URL.SingleProperty}/:id`}
          element={<SingleProperty />}
        />
        <Route path={`${PAGES_URL.MatchedMates}`} element={<MatchedMates />} />
      </Route>
      <Route path={`${PAGES_URL.HowItWorks}`} element={<HowItWorks />} />

      <Route path={`${PAGES_URL.Metric}`} element={<Metric />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
