import emailjs from "@emailjs/browser";
emailjs.init({
  publicKey: process.env.REACT_APP_MAIL_PUBLIC_KEY,
  blockHeadless: true,
  blockList: {
    list: ["foo@emailjs.com", "bar@emailjs.com"],
    watchVariable: "email",
  },
  limitRate: {
    id: "app",
    throttle: 10000,
  },
});

export const send = async (templateParams: any) => {
  const service_id: any = process.env.REACT_APP_MAIL_SERVICE_ID!;
  const template_id: any = process.env.REACT_APP_MAIL_TEMPLATE_ID!;
  return await emailjs.send(
    service_id,
    template_id,
    templateParams,
  );
};
