import React from "react";
import AppComponent from "../../components/layouts/AppComponent";

const MySubscription = () => {
  return (
    <AppComponent>
      <div></div>
    </AppComponent>
  );
};
export default MySubscription;
