import React from "react";

type Props = {
  weight: any;
};

const Badge = ({ weight }: Props) => {
  let rank = null;
  if (weight >= 0.85) {
    rank = {
      label: "Perfect Match",
      theme: "#80050f",
    };
  } else if (weight >= 0.7) {
    rank = {
      label: "Excellent Match",
      theme: "#DC2F02",
    };
  } else if (weight >= 0.55) {
    rank = {
      label: "Great Match",
      theme: "#E85D04",
    };
  } else if (weight >= 0.4) {
    rank = {
      label: "Average Match",
      theme: "#F48C06",
    };
  } else {
    rank = {
      label: "No Match",
      theme: "#FFBA08",
    };
  }
  return (
    <span
      className={`badge mb-1 fs-xs`}
      style={{ backgroundColor: rank?.theme }}
    >
      <li className="list-inline-item pe-1">
        <i className="fi-star mt-n1 me-1  align-middle"></i>
        {rank?.label}
      </li>
    </span>
  );
};

export default Badge;
