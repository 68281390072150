const PAGES_URL = {
  Landing: "/",
  ContactUs: "/contact-us",
  HowItWorks: "/how-it-works",
  SignUp: "/signup",
  Login: "/login",
  Property: "/property",
  SingleProperty: "/property",
  Profile: "/account/overview",
  ProfilePreference: "/account/preference",
  ProfileUpdate: "/account/update",
  PasswordUpdate: "/account/password",
  Metric: "/search",
  SingleMates: "/mates",
  MatchedMates: "/mates",
  Subscription: "/account/subscription",
};

export default PAGES_URL;
