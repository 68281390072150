import Button from "./Buttons";

type Props = {
  children: any;
  title: string;
  buttonLabel?: string;
  submit: Function;
  count?: any;
  loading: any;
};

const Modal = ({
  children,
  title,
  buttonLabel,
  submit,
  count,
  loading,
}: Props) => {
  const handleSubmit = () => {
    submit();
  };
  return (
    <div id="modalFullScreen" className={`modal fade`} role="dialog">
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">{title}</h6>

            <h6 className="ms-auto">
              {count && count + " Properties Available"}
            </h6>
            {/* <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              id="close"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <Button
              loading={loading}
              onClick={handleSubmit}
              className="btn btn-primary btn-shadow btn-sm"
              type="button"
            >
              {buttonLabel ? buttonLabel : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
