import React from "react";
import { BsXLg } from "react-icons/bs";
import RadialBar from "../../../components/charts/RadialBar";
import PAGES_URL from "../../../routes";
import { Link } from "react-router-dom";

type Props = {
  progress: number;
  title: string;
  percent: number;
  subtext?: string;
};

const Header = ({ progress, title, percent, subtext }: Props) => {
  const plotOptions = {
    radialBar: {
      track: {
        show: true,
        strokeWidth: "100%",
        opacity: 1,
        margin: 4,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.5,
        },
      },
      dataLabels: {
        show: true,
        name: {
          show: true,
          fontSize: "14px",
          fontWeight: 600,
          color: "#800411",
          offsetY: 5,
        },
        value: { show: false },
      },
    },
  };
  return (
    <section>
      <div className="row">
        <div className="d-flex mt-5 mb-5 align-items-center">
          <div>
            {" "}
            <Link className="text-dark" to={PAGES_URL.Landing}>
              {" "}
              <BsXLg className="fs-xxl" />
            </Link>
          </div>
          <div className="ms-auto">
            <RadialBar
              plotOptions={plotOptions}
              series={[percent]}
              labels={[progress]}
              height={100}
            />{" "}
          </div>
        </div>
        <div className="my-lg-2">
          <div className="col-lg-10">
            <h4 className="display-5  fw-normal ">{title}</h4>
            <h4 className="h4  fw-normal ">{subtext}</h4>
            <div className="progress mb-3" style={{ height: "4px" }}>
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                style={{ width: `${percent}%` }}
                aria-valuenow={percent}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
