import React from "react";
import LogoLight from "../assets/img/logo-light.png";
import { Link } from "react-router-dom";
import PAGES_URL from "../routes";
import Avatar from "../assets/img/avatar.png";
import { connect } from "react-redux";
import { logOutAction } from "../redux/actions/auth.action";
import {
  GrBarChart,
  GrCreditCard,
  GrLock,
  GrMultiple,
  GrUser,
} from "react-icons/gr";

type Props = {
  theme?: boolean;
  isAuthenticated?: string;
  user: any;
  logout: Function;
};

const Header = ({ theme, isAuthenticated, user, logout }: Props) => {
  return (
    <header className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link
          className="navbar-brand me-xl-4 order-1 order-lg-0"
          to={PAGES_URL.Landing}
        >
          <img
            className=""
            style={{ width: "100px" }}
            src={LogoLight}
            alt="Metricise"
          />
        </Link>
        <button
          className="navbar-toggler order-lg-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {!isAuthenticated && (
          <>
            <Link
              className={` ps-0 order-lg-3 order-1 text-decoration-none text-white fs-sm me-lg-3`}
              to={PAGES_URL.Login}
            >
              <i className="fi-user me-2"></i>Sign in
            </Link>
            <Link
              className="btn btn-primary btn-sm ms-lg-2  d-none d-lg-block order-1 order-lg-3"
              to={PAGES_URL.SignUp}
            >
              <i className="fi-home me-2"></i>Sign up
            </Link>
          </>
        )}
        {isAuthenticated && (
          <>
            <div className="d-flex ms-lg-4 align-items-center order-lg-3 order-2">
              <div className="dropdown">
                <Link to="#" className="me-lg-2 " data-bs-toggle="dropdown">
                  <img
                    className="rounded-circle"
                    src={user?.avatar || Avatar}
                    width="35"
                    alt={user?.fullname}
                  />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <div
                    className="d-flex align-items-start border-bottom px-3 py-1 mb-2"
                    style={{ width: "16rem" }}
                  >
                    <img
                      className="rounded-circle"
                      src={user?.avatar || Avatar}
                      width="48"
                      alt={user?.fullname}
                    />
                    <div className="ps-2">
                      <h6 className="fs-base mb-0">{user.fullname}</h6>
                      <div className="badge text-primary border-lg border-primary ">
                        FREE PLAN
                      </div>
                      {/* <div className="star-rating star-rating-sm">
                        <i className="star-rating-icon fi-star-filled active"></i>
                        <i className="star-rating-icon fi-star-filled active"></i>
                        <i className="star-rating-icon fi-star-filled active"></i>
                        <i className="star-rating-icon fi-star-filled active"></i>
                        <i className="star-rating-icon fi-star-filled active"></i>
                      </div> */}
                      <div className="fs-xs py-2">
                        {user.phone}
                        <br />
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <Link to={PAGES_URL.ProfileUpdate} className="dropdown-item">
                    <GrUser className="me-2" />
                    Personal Details
                  </Link>
                  <Link to={PAGES_URL.Profile} className="dropdown-item">
                    <GrBarChart className="me-2" />
                    Investment Preferences
                  </Link>
                  <Link to="#" className="dropdown-item">
                    <GrCreditCard className="me-2" />
                    My Subscription
                  </Link>
                  <Link
                    to={PAGES_URL.ProfilePreference}
                    className="dropdown-item"
                  >
                    <GrMultiple className="me-2" />
                    My Metric Base
                  </Link>
                  <Link to={PAGES_URL.PasswordUpdate} className="dropdown-item">
                    <GrLock className="me-2" />
                    Password & Security
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to={PAGES_URL.ContactUs} className="dropdown-item">
                    Help
                  </Link>
                  <Link
                    onClick={() => logout()}
                    to="#"
                    className="dropdown-item"
                  >
                    Sign Out
                  </Link>
                </div>
              </div>
              <div className="d-none d-lg-flex  flex-column  ">
                <Link
                  to={PAGES_URL.ProfileUpdate}
                  className="fs-xs text-white text-decoration-none"
                >
                  {user.fullname}
                </Link>
                <span className="fs-xs text-white fw-normal">
                  {" "}
                  {user.email}
                </span>
              </div>
            </div>
          </>
        )}

        <div className="collapse navbar-collapse  order-lg-2" id="navbarNav">
          <ul
            className="navbar-nav navbar-nav-scroll"
            style={{ maxHeight: "35rem" }}
          >
            <li className="nav-item active">
              <Link className="nav-link fw-normal" to={PAGES_URL.Landing}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-normal" to={PAGES_URL.Property}>
                Property
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-normal" to={PAGES_URL.MatchedMates}>
                MetricMates
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-normal" to={PAGES_URL.HowItWorks}>
                How It Works
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link fw-normal" to={PAGES_URL.ContactUs}>
                Contact Us
              </Link>
            </li>
            {!isAuthenticated && (
              <li className="nav-item d-lg-none">
                <Link className="nav-link" to={PAGES_URL.Login}>
                  <i className="fi-user me-2"></i>Sign in
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
