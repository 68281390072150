import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PropertySkeleton = () => {
  let data = Array(18).fill("Dummy");

  return (
    <SkeletonTheme>
      <div className="container mt-5 mb-md-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 position-relative overflow-hidden pb-5 ">
            <div className="d-flex  align-items-sm-center align-items-stretch my-2">
              <div className="d-flex align-items-center flex-shrink-0=">
                <Skeleton width={200} className="me-3" />
              </div>
              <Skeleton width={100} />
              <div className="d-none d-sm-flex ms-auto align-items-center  flex-shrink-0">
                <Skeleton width={50} />
              </div>
            </div>
            <div className="row">
              {data.map((el: any, index: any) => (
                <div className="col-sm-6 col-xl-4" key={index}>
                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="tns-carousel-wrapper card-img-top card-img-hover">
                      <div className="position-absolute w-100 start-0 top-0 pt-3 ps-3 ">
                        <div className="d-flex flex-column  align-items-start ">
                          {/* <Badge weight={property.ranking} />
                          {metrics.slice(0, 3)?.map((item: any) => (
                            <div className="">
                              <div
                                className="text-dark  badge bg-secondary mb-1 text-end rounded-pill"
                                style={{ fontSize: "11px" }}
                              >
                                {item.label} {splitString(item.name)}
                              </div>
                            </div>
                          ))} */}
                          <div className="ms-auto row  me-2"></div>
                        </div>
                      </div>

                      <div className="content-overlay end-0 top-0 pt-3 pe-3"></div>
                      <div className="">
                        <Skeleton height={240} />
                      </div>
                    </div>
                    <div className="card-body position-relative pb-3">
                      <div className="d-flex fs-xs align-items-center">
                        <Skeleton width={100} />

                        <li className="list-inline-item pe-1 ms-auto ">
                          <Skeleton />
                        </li>
                      </div>
                      <h3 className="h6 mb-2 fs-base">
                        <Skeleton />
                      </h3>
                      <p className="mb-2 fw-normal fs-sm ">
                        <Skeleton />
                      </p>
                      <div className="text-primary">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center pt-3 text-nowrap">
                      <ul className="list-unstyled d-flex fs-sm">
                        <li className="">
                          <Skeleton />
                        </li>
                        <li className="ms-3">
                          <Skeleton />
                        </li>
                        <li className="ms-3">
                          <Skeleton />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default PropertySkeleton;
