import React from "react";
import {
  BsArrowClockwise,
  BsArrowRight,
  BsBuilding,
  BsBuildings,
  BsCheckCircleFill,
} from "react-icons/bs";
import { setMetricPage, setSearch } from "../../redux/actions/metric.action";
import { connect } from "react-redux";
import Property from "./components/Property";
import Area from "./components/Area";
import Investment from "./components/Investment";
import MetricReview from "./components/MetricReview";
import Header from "./components/Header";

const Metric = ({ searchFilter, search, pageId, nextPage }: any) => {
  const next = () => {
    nextPage(2);
  };

  const handleSubmit = (data: any) => {
    searchFilter({ ...search, type: data });
  };

  const handleRadioOnchange = (e: any) => {
    const { name, value } = e.target;

    if (value === "reset" && search[name]) {
      delete search[name];
      searchFilter({ ...search });
    } else {
      searchFilter({ ...search, [name]: value });
    }
  };

  return (
    <div className="container vh-100">
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          bottom: -75,
          right: 0,
          zIndex: -1,
        }}
      ></div>
      {pageId === 1 && (
        <div className="row h-100">
          <Header
            progress={1}
            percent={20}
            title={` Let's find your ideal property.`}
            subtext=" What are you looking to do on our website today?"
          />

          <section className="w-100 ">
            <form action="row h-100 align-items-center mt-5">
              <div className="col-lg-12">
                {/* <label className="form-label d-block fw-normal mb-2 mt-3 pb-1">
                  Choose preferred choice
                </label> */}
                <div className="d-flex flex-column mt-3">
                  <button
                    style={{ height: "100px" }}
                    className={`icon-box card  flex-row align-items-center w-sm-100 ps-2 pe-4 me-3 mb-4 ${
                      search && search.type === "Rent" && "active"
                    } `}
                    onClick={() => handleSubmit("Rent")}
                    type="button"
                  >
                    <div className="icon-box-media bg-faded-dark text-dark rounded-circle ms-4 me-2 ">
                      <BsBuilding />
                    </div>
                    <h3 className="icon-box-title fs-xxl ps-1 mb-0 ">Rent</h3>
                    {search && search.type === "Rent" && (
                      <BsCheckCircleFill
                        size={15}
                        className="icon position-absolute"
                        style={{ top: "5px", right: "5px" }}
                      />
                    )}
                  </button>

                  <button
                    style={{ height: "100px" }}
                    onClick={() => handleSubmit("Sale")}
                    className={`icon-box card  flex-row align-items-center  py-2 ps-2 pe-4 me-3 ${
                      search && search.type === "Sale" && "active"
                    }`}
                    type="button"
                  >
                    <div className="icon-box-media bg-faded-dark text-dark rounded-circle ms-4 me-2">
                      <BsBuildings />
                    </div>
                    <h3 className="icon-box-title fs-xxl  ps-1 mb-0">Buy</h3>
                    {search && search.type === "Sale" && (
                      <BsCheckCircleFill
                        size={15}
                        className="icon position-absolute"
                        style={{ top: "5px", right: "5px" }}
                      />
                    )}
                  </button>
                </div>
              </div>
              {/* <div className="col-md-12 mt-5">
                <ReactSearchAutocomplete
                  items={places}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  autoFocus
                  formatResult={formatResult}
                  placeholder="Search places"
                />
              </div> */}
              <div className="mt-5">
                <label className="form-label d-block fw-normal mb-2 pb-1">
                  Bedrooms
                </label>
                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Choose number of bedrooms"
                >
                  <input
                    className="btn-check"
                    type="radio"
                    id="studio"
                    value={"studio"}
                    name="bedrooms"
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "studio"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="studio"
                  >
                    Studio
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-1"
                    name="bedrooms"
                    value={1}
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "1"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-1"
                  >
                    1
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-2"
                    value={2}
                    name="bedrooms"
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "2"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-2"
                  >
                    2
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-3"
                    name="bedrooms"
                    value={3}
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "3"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-3"
                  >
                    3
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-4"
                    name="bedrooms"
                    value={4}
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "4"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-4"
                  >
                    4
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-5"
                    name="bedrooms"
                    value={5}
                    onChange={handleRadioOnchange}
                    checked={search && search.bedrooms === "5"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-5"
                  >
                    5+
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bedrooms-reset"
                    name="bedrooms"
                    value={"reset"}
                    onChange={handleRadioOnchange}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bedrooms-reset"
                  >
                    <BsArrowClockwise />
                  </label>
                </div>
              </div>
              <div className="mt-4">
                <label className="form-label d-block fw-normal mb-2 pb-1">
                  Bathrooms
                </label>
                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Choose number of bedrooms"
                >
                  {/* <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-0"
                    name="bathrooms"
                    value={0}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "0"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-0"
                  >
                    Studio
                  </label> */}
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-1"
                    name="bathrooms"
                    value={1}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "1"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-1"
                  >
                    1
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-2"
                    name="bathrooms"
                    value={2}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "2"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-2"
                  >
                    2
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-3"
                    name="bathrooms"
                    value={3}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "3"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-3"
                  >
                    3
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-4"
                    name="bathrooms"
                    value={4}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "4"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-4"
                  >
                    4
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-5"
                    name="bathrooms"
                    value={5}
                    onChange={handleRadioOnchange}
                    checked={search && search.bathrooms === "5"}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-5"
                  >
                    5+
                  </label>
                  <input
                    className="btn-check"
                    type="radio"
                    id="bathrooms-reset"
                    name="bathrooms"
                    value={"reset"}
                    onChange={handleRadioOnchange}
                  />
                  <label
                    className="btn btn-outline-secondary fw-normal"
                    htmlFor="bathrooms-reset"
                  >
                    <BsArrowClockwise />
                  </label>
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                {/* {search && search.name && (
                  <>
                    3443 Properties found in{" "}
                    <span className="fw-bold">{search.name}</span>
                  </>
                )} */}
              </div>
            </form>
          </section>

          <div className="d-flex">
            <button onClick={next} className="btn w-25 text-dark  ms-auto">
              <BsArrowRight size={40} className="ms-auto" />
            </button>
          </div>
        </div>
      )}

      {pageId === 2 && <Property search={search} />}
      {pageId === 3 && <Area search={search} />}
      {pageId === 4 && <Investment />}
      {pageId === 5 && <MetricReview />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  search: state.metric.search,
  pageId: state.metric.pageId,
});

const mapDispatchToProps = (dispatch: any) => ({
  searchFilter: (data: any) => dispatch(setSearch(data)),
  nextPage: (data: any) => dispatch(setMetricPage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
