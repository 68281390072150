import React from "react";
import { usePagination, DOTS } from "./usePagination";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange && paginationRange?.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  return (
    <nav className="border-top pb-md-4 pt-4 mt-2" aria-label="Pagination">
      <ul className="pagination d-flex align-items-center">
        {/* Left navigation arrow */}
        {currentPage !== 1 && (
          <li className="page-item" onClick={onPrevious}>
            <span className="page-link  d-flex align-items-center">
              <BsChevronLeft /> <span className="d-none d-sm-block">Prev</span>
            </span>
          </li>
        )}
        {paginationRange &&
          paginationRange.map((pageNumber, idx) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return <li className="page-item">&#8230;</li>;
            }

            // Render our Page Pills
            return (
              <li
                className={`page-item fw-normal ${
                  pageNumber === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => onPageChange(pageNumber)}
                  className="page-link "
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
        {/*  Right Navigation arrow */}
        {currentPage !== lastPage && (
          <li className="page-item" onClick={onNext}>
            <span className="page-link page-link-static d-flex align-items-center">
              <span className="d-none d-sm-block">Next</span> <BsChevronRight />
            </span>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
