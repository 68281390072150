import _ from "lodash";
import { getTubStationDistance } from "../redux/actions/filter";

export const distance = (position: any, property_position: any) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(property_position.latitude - position.latitude); // deg2rad below
  var dLon = deg2rad(property_position.longitude - position.longitude);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(position.latitude)) *
      Math.cos(deg2rad(property_position.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const filter = async (options: any, data: any) => {
  let arr: any = null;
  let station = await getTubStationDistance(options?.tube_station || null);
  let records = data.map((el: any) => {
    if (station && station.length > 0) {
      el.tube_station_time = nearest_location(
        station,
        el,
      );
      el.tube_station = options.tube_station;
    }
    return el;
  });

  let filtered = records?.filter((obj: any) => {
    if (options) {
      return Object.entries(options).every(([key, filterArr]: any) => {
        if (
          key === "price" || "debt_per_building" ||
          "area_leverage" || "mean_income" || "gross_yields" ||
          "broadband_speed" || "crime_rate"
        ) {
          arr = filterArr.length === 0 ||
            (obj[key] <= filterArr.max && obj[key] >= filterArr.min);
        }

        if (
          key === "green_areas" || key === "station_proximity" ||
          key === "supermarket_proximity" || key === "primary_proximity" ||
          key === "secondary_proximity" || key === "private_proximity"
        ) {
          arr = filterArr.length === 0 || (obj[key].distance <= filterArr.max &&
            obj[key].distance >= filterArr.min);
        }
        if (
          key === "property_type" ||
          key === "tenure" ||
          key === "bedrooms" ||
          key === "bathrooms" || key === "educational_levels" ||
          key === "tube_station"
        ) {
          arr = filterArr.length === 0 || filterArr.includes(obj[key]);
        }

        if (key === "features") {
          arr = filterArr.length === 0 ||
            filterArr.some((el: any) => obj[key].includes(el)); //obj[key].indexOf(filterArr);
        }

        if (key === "tube_station_time") {
          arr = filterArr.length === 0 ||
            obj[key] <= filterArr;
        }

        return arr;
      });
    } else {
      return [];
    }
  });

  return filtered;
};

export const rangeValues = (data: any, name: string) => {
  let arr = data?.map((el: any) => (el[name].distance || el[name]));
  let MaxFn = (arr: any) => _.maxBy<any>(arr, (o) => o);
  let MinFn = (arr: any) => _.minBy<any>(arr, (o) => o);

  return { max: MaxFn(arr), min: MinFn(arr) };
};

function deg2rad(deg: any) {
  return deg * (Math.PI / 180);
}

const nearest_location = (
  poi: any,
  property_location: any,
) => {
  let walking_pace_kmh = 4.82803;

  let firstLocation = poi[0];

  let closest = distance(property_location, {
    longitude: firstLocation.longitude,
    latitude: firstLocation.latitude,
  });

  let difference = null;
  let newObj: any = null;
  poi.map((el: any) => {
    if (el.line) {
      let location = { latitude: el.latitude, longitude: el.longitude };
      difference = distance(property_location, location);
      if (difference < closest) {
        closest = difference;
        newObj = closest;
      }
    }
    return newObj;
  });
  return Math.round((newObj / walking_pace_kmh) * 60 * 1.2);
};
