import React from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "../../../routes";
import PlaceholderImg from "../../../assets/img/property-placeholde.jpeg";
import Badge from "./Badge";
import { currencyFormat } from "../../../helpers/String";

const PropertyCard = ({ property, metrics }: any) => {
  return (
    <>
      <div className="col-sm-6 col-xl-4">
        <div className="card shadow-sm card-hover border-0 h-100">
          <div className="tns-carousel-wrapper card-img-top card-img-hover">
            <Link
              className="img-overlay"
              to={`${PAGES_URL.SingleProperty}/${property.slug}`}
            ></Link>
            <div className="position-absolute w-100 start-0 top-0 pt-3 ps-3 ">
              <div className="d-flex flex-column  align-items-start ">
                <Badge weight={property.ranking} />
                {/* {metrics.slice(0, 3)?.map((item: any, idx: any) => (
                  <div className="" key={idx}>
                    <div
                      className="text-dark  badge bg-secondary mb-1 text-end rounded-pill"
                      style={{ fontSize: "11px" }}
                    >
                      {item.label} {splitString(item.name)}
                    </div>
                  </div>
                ))} */}
                <div className="ms-auto row  me-2"></div>
              </div>
            </div>

            <div className="content-overlay end-0 top-0 pt-3 pe-3"></div>
            <div className="">
              <img
                src={property?.images[0] || PlaceholderImg}
                style={{ width: "100%", height: "250px" }}
                alt={property.agent_name}
              />
            </div>
          </div>
          <div className="card-body position-relative pb-3">
            <div className="d-flex fs-xs align-items-center">
              {property.listing === "Sale" ? (
                <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary">
                  For sale
                </h4>
              ) : (
                <h4
                  className="mb-1 fs-xs fw-normal text-uppercase "
                  style={{ color: "blue" }}
                >
                  For rent{" "}
                </h4>
              )}
              {property.epc_rating && (
                <li className="list-inline-item pe-1 ms-auto ">
                  <i className="fi-star-filled mt-n1 me-1  text-warning align-middle"></i>
                  EPC Rating ({property.epc_rating})
                </li>
              )}
            </div>
            <h3 className="h6 mb-2 fs-base">
              <Link
                className="nav-link stretched-link fs-sm"
                to={`${PAGES_URL.SingleProperty}/${property.slug}`}
              >
                {property.agent_name}
              </Link>
            </h3>
            <p className="mb-2 fw-normal fs-sm ">{property.address}</p>
            <div className="text-primary">
              {currencyFormat(property.price)}
              {property.listing === "Rent" ? "PCM" : ""}
            </div>
          </div>
          <div className="card-footer d-flex align-items-center justify-content-center pt-3 text-nowrap">
            <ul className="list-unstyled d-flex fs-sm">
              <li className="">
                <i className="fi-bed mt-n1 me-2 fs-lg align-middle"></i>
                {property.bedrooms} Bed
              </li>
              <li className="ms-3">
                <i className="fi-bath mt-n1 me-2 fs-lg align-middle"></i>
                {property.bathrooms} Bath
              </li>
              {property.parking && (
                <li className="ms-3">
                  <i className="fi-car mt-n1 me-2 fs-lg align-middle"></i>
                  Parking
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyCard;
