import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/layouts/AppComponent";
import { getAllProperty } from "../../redux/actions/property";
import PropertyCard from "./components/PropertyCard";
import Pagination from "../../components/forms/Pagination";
import { setFilteredData } from "../../redux/actions/filter";
import AdvancedFilter from "./components/AdvancedFilter";
import PropertySkeleton from "../../components/skeleton/Property";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { Link } from "react-router-dom";
import PAGES_URL from "../../routes";

let PageSize = 18;

type Props = {
  propertyData: any;
  fetch: Function;
  metrics: any;
  filters: any;
};

const Properties = ({ propertyData, metrics, fetch, filters }: Props) => {
  const [filteredData, setFilteredData] = useState<any>(propertyData);
  const [currentPage, setCurrentPage] = useState(1);
  const [index, setIndex] = useState<any>(null);

  let createTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (index === 1) {
      filteredData?.sort((a: any, b: any) => a.price - b.price);
    } else if (index === 2) {
      filteredData?.sort((a: any, b: any) => a.price - b.price).reverse();
    } else if (index === 3) {
      filteredData?.sort((a: any, b: any) => a.created_at - b.created_at);
    } else if (index === 4) {
      filteredData
        ?.sort((a: any, b: any) => a.created_at - b.created_at)
        .reverse();
    }
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredData, index]);

  useEffect(() => {
    if (!filteredData) {
      fetch();
    }
  }, [fetch, filteredData]);

  const handleOnSorting = (e: any) => {
    setIndex(e.target.options.selectedIndex);
  };

  return (
    <>
      <AppComponent dark>
        <LoaderComponent
          topStyle={"100px"}
          progress={filteredData?.length > 0 ? 100 : 25}
          loading={filteredData?.length > 0 ? false : true}
        />
        {filteredData ? (
          <div className="container mt-5 mb-md-4">
            <div className="row">
              <div className="col-lg-12 col-xl-12 position-relative overflow-hidden pb-5 ">
                <div className="d-flex  align-items-sm-center align-items-stretch my-2">
                  <div className="d-flex align-items-center flex-shrink-0=">
                    <select
                      name="sorting"
                      className="form-select form-select-sm"
                      onChange={handleOnSorting}
                      id="sortby"
                    >
                      <option defaultChecked>Multi-Metric Ranking</option>
                      <option value={"lowest"}>Price: Lowest First</option>
                      <option value={"highest"}>Price: Highest First</option>
                      <option value={"newest"}>Recency: Newest First</option>
                      <option value={"oldest"}>Recency: Oldest First</option>
                    </select>
                  </div>
                  <button
                    className="ms-2 btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modalFullScreen"
                    type="button"
                  >
                    <i className="fi-rotate-right me-2"></i> Filters
                  </button>
                  <Link
                    to={PAGES_URL.Metric}
                    className="ms-2 btn btn-sm btn-default text-dark"
                  >
                    <i className="fi-pencil me-2"></i> Edit Metrics
                  </Link>
                  <div className="d-none d-sm-flex ms-auto align-items-center  flex-shrink-0">
                    <i className="fi-check-circle me-2 "></i>
                    <span className="fs-xl fw-bold">
                      {filteredData.length} Results
                    </span>
                  </div>
                </div>
                <div className="row g-4 py-4">
                  {createTableData &&
                    createTableData.map((item: any, idx: number) => (
                      <PropertyCard
                        key={idx}
                        property={item}
                        metrics={metrics}
                      />
                    ))}
                </div>

                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={filteredData?.length}
                  pageSize={PageSize}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        ) : (
          <PropertySkeleton />
        )}
        <AdvancedFilter setFilteredData={setFilteredData} />
      </AppComponent>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  propertyData: state.properties.propertyData,
  filters: state.properties.filters,
  metrics: state.metric.selectedMetrics,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetch: () => dispatch(getAllProperty()),
  setFilters: (data: any) => dispatch(setFilteredData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
