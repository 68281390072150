import supabase from "../lib/supabase/client";
import { currencyFormat } from "./String";

const triangular_numbers = [
  0,
  1,
  3,
  6,
  10,
  15,
  21,
  28,
  36,
  45,
  55,
  66,
  78,
  91,
  105,
  120,
  136,
  153,
  171,
  190,
  210,
  231,
  253,
  276,
  300,
  325,
  351,
  378,
  406,
  435,
  465,
  496,
  528,
  561,
  595,
  630,
  666,
  703,
  741,
  780,
  820,
  861,
  903,
  946,
  990,
  1035,
  1081,
  1128,
  1176,
  1225,
  1275,
  1326,
  1378,
  1431,
];

export const get_metrics_weight = (record: any) => {
  let total = record.length;
  let weight = record.map((item: any, index: number) => {
    let weight = (((total - index) / total) * total) /
      triangular_numbers[total];

    item.value = weight;
    let newdata = item.value;
    return newdata;
  });
  // normalised_metrics(record);
  return weight;
};

export const reduce_data_object = async (properties: any) => {
  const demograpic_features = await get_demographic_records();
  const poi = await get_poi_records();
  const histogram = await get_histogram_records();
  const educational_levels = await get_educational_levels();

  let records = properties?.map((el: any) => {
    let location = { latitude: el.latitude, longitude: el.longitude };
    el.crime = exists(demograpic_features, el.postcode, 434);
    el.population = exists(demograpic_features, el.postcode, 473);
    el.average_age = exists(demograpic_features, el.postcode, 420);
    el.broadband_speed = exists(demograpic_features, el.postcode, 428);
    el.population_density = exists(demograpic_features, el.postcode, 157);
    el.rental_affordability = exists(demograpic_features, el.postcode, 505);
    el.area_leverage = exists(demograpic_features, el.postcode, 162);
    el.debt_per_building = exists(demograpic_features, el.postcode, 160);
    el.mortgage_affordability = exists(demograpic_features, el.postcode, 504);
    el.credit_score = exists(demograpic_features, el.postcode, 163);
    el.mean_income = exists(demograpic_features, el.postcode, 436);
    el.property_type = el.type;

    el.educational_levels = educational_levels?.filter((inner: any) =>
      el.postcode === inner.postcode
    )[0]?.level;
    el.primary_proximity = nearest_location(
      poi,
      location,
      "first_primary_and_infant_schools",
    );
    el.secondary_proximity = nearest_location(
      poi,
      location,
      "broad_age_range_and_secondary_state_schools",
    );
    el.private_proximity = nearest_location(
      poi,
      location,
      "independent_and_preparatory_schools",
    );
    el.station_proximity = nearest_location(poi, location, "Tube Station");
    el.supermarket_proximity = nearest_location(
      poi,
      location,
      "supermarket_chains",
    );
    el.green_areas = nearest_location(poi, location, "Park or Garden");
    el.median_rents = median_rents(histogram, el.postcode, el.bedrooms);
    el.gross_yields = gross_yields(
      histogram,
      el.postcode,
      el.bedrooms,
      el.price,
    );

    el.square_footage = el.sqft;

    return el;
  });
  return records;
};

const normalised_metrics = async (records: any, metrics: any) => {
  let selected_metrics = Object.keys(records).map((key) =>
    records[key]["name"]
  );

  let newArray: any = [];

  newArray = metrics?.map((el: any, key: any) => {
    return selected_metrics.map((key: any, index: any) => el?.[key]);
  });
  console.log(newArray);
  let weight = get_metrics_weight(records);
  let base = first_base_values(newArray, weight);

  let ideal = ideal_values(base, records);

  let si = base.map((el: any, index: any) => {
    let rows = el.map((et: any, index: any) => {
      return Math.pow(et - ideal.best[index], 2);
    });
    return rows.reduce((a: any, b: any) => a + b, 0);
  });

  si = si.map((el: any) => Math.sqrt(el));
  let sv = base.map((el: any, index: any) => {
    let rows = el.map((et: any, index: any) => {
      return Math.pow(et - ideal.worst[index], 2);
    });
    return rows.reduce((a: any, b: any) => a + b, 0);
  });
  sv = sv.map((el: any) => Math.sqrt(el));

  let performace_score = sv.map((el: any, index: any) => el / (si[index] + el));

  return metrics.map((
    el: any,
    index: any,
  ) => {
    el.ranking = performace_score[index];
    return el;
  });
};

const ideal_values = (metrics: any, records: any) => {
  let best = get_ideal_best(metrics, records);
  let worst = get_ideal_worst(metrics, records);
  return { best, worst };
};

var get_ideal_best = (metrics: any, record: any) => {
  let arr: any = [];
  record.map((el: any, index: any) => {
    let rows = metrics.map(function (elt: any) {
      return elt[index];
    });
    if (el.label === "High") {
      arr.push(Math.max.apply(null, rows));
    } else {
      arr.push(Math.min.apply(null, rows));
    }
    return arr;
  });
  return arr;
};

var get_ideal_worst = (metrics: any, record: any) => {
  let arr: any = [];
  record.map((el: any, index: any) => {
    let rows = metrics.map(function (elt: any) {
      return elt[index];
    });
    if (el.label === "High") {
      arr.push(Math.min.apply(null, rows));
    } else {
      arr.push(Math.max.apply(null, rows));
    }
    return arr;
  });
  return arr;
};

const first_base_values = (metrics: any, weight: any) => {
  let data = metrics.map((outer: any, okey: any) => {
    return outer.map((inner: any) =>
      typeof inner === "object" ? inner?.distance : inner
    );
  });
  let total_sum = data?.reduce((outer: any, out: any) =>
    outer.map((inner: any, ine: any) => inner + out[ine], 0)
  );
  let sqrt = total_sum.map((el: any) => Math.sqrt(el));

  let first_base = metrics.map((outer: any, okey: any) => {
    return outer.map((inner: any, index: any) => {
      return (typeof inner === "object"
        ? inner?.distance
        : inner / sqrt[index]) * weight[index];
    });
  });
  return first_base;
};

const get_demographic_records = async () => {
  const { data } = await supabase.from("demographic_features").select("*");
  return data;
};
const get_educational_levels = async () => {
  const { data } = await supabase.from("educational_levels").select("*");
  return data;
};

const get_histogram_records = async () => {
  const { data } = await supabase.from("histograms").select("*");
  return data;
};

const get_poi_records = async () => {
  const { data } = await supabase.from("point_of_interests").select("*");
  return data;
};

const get_property_records = async (search: any) => {
  let query: any = supabase.from("properties").select("*");
  if (search.type) query = query.eq("listing", search.type);
  // if (search.bedrooms) query = query.eq("bedrooms", search.bedrooms);
  // if (search.bathrooms) query = query.eq("bathrooms", search.bathrooms);
  return await query;
};

const exists = (obj: any, compare: any, indicator: any) => {
  let data: any = null;
  Object.keys(obj).some((k: any) => {
    if (obj[k].postcode === compare && obj[k].indicator_id === indicator) {
      data = obj[k].feature["12m"];
    }
    return data;
  });
  return data;
};

const nearest_location = (
  poi: any,
  property_location: any,
  label: any,
) => {
  let firstLocation = poi?.filter((el: any) => el.category === label)[0];

  let closest = distance(property_location, {
    longitude: firstLocation.longitude,
    latitude: firstLocation.latitude,
  });
  // console.log(closest);

  let difference = null;
  let newObj: any = null;
  let newlocation: any = null;
  poi.map((el: any) => {
    if (el.category === label) {
      let location = { latitude: el.latitude, longitude: el.longitude };
      difference = distance(property_location, location);
      if (difference < closest) {
        closest = difference;
        newlocation = { latitude: el.latitude, longitude: el.longitude };
        newObj = closest;
      }
    }
    return newObj;
  });
  return {
    distance: newObj,
    ...newlocation,
  };
};

function distance(position: any, property_position: any) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(property_position.latitude - position.latitude); // deg2rad below
  var dLon = deg2rad(property_position.longitude - position.longitude);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(position.latitude)) *
      Math.cos(deg2rad(property_position.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: any) {
  return deg * (Math.PI / 180);
}

const median_rents = (histogram: any, postcode: any, bedrooms: number) => {
  let sum = 0;
  let newrec: any = [];

  let records = histogram.filter((el: any) =>
    el.area_id.includes(postcode) && el.bedroom === bedrooms
  );

  if (records.length > 0) {
    let height = records.reduce((a: any, b: any) => a + b.height, 0) / 2;
    records.map((el: any) => {
      sum += el.height;
      if (sum > height) {
        newrec.push(el);
      }
      return true;
    });

    let upper = newrec[0]?.upper_bound;
    let lower = newrec[0]?.lower_bound;
    let bound_height = newrec[0]?.height;
    let bound_difference = upper - lower;

    let radian = bound_height !== 0 ? (bound_height / height) * 100 : 0;
    let bound = upper - ((bound_difference * radian) / 100);
    return bound;
  }
};

const gross_yields = (
  histogram: any,
  postcode: any,
  bedrooms: any,
  price: any,
) => {
  let median_rent = median_rents(histogram, postcode, bedrooms);

  let gross_yields = median_rent ? ((median_rent * 12) / price) * 100 : 0;
  return gross_yields;
};

export const get_metrics_data = async (
  filter: any,
  selected_metrics: any,
  payload?: any,
) => {
  if (payload?.length === 0 || payload === undefined) {
    payload = (await get_property_records(filter)).data;
  }

  if (payload.length === 0) {
    return false;
  }

  let property_data = await reduce_data_object(payload);
  console.log(property_data);
  let filtered_data = property_data?.filter((el: any) =>
    el.listing === filter.type && el.square_footage !== null &&
    el.green_areas?.distance !== null && el.bedrooms !== null &&
    el.bathrooms !== null && el.primary_proximity?.distance !== null &&
    el.secondary_proximity?.distance !== null &&
    el.private_proximity?.distance !== null &&
    el.median_rents !== null &&
    el.supermarket_proximity?.distance !== null &&
    el.station_proximity?.distance !== null &&
    el.area_leverage !== null &&
    el.debt_per_building !== null &&
    el.mean_income !== null &&
    el.gross_yields !== null &&
    el.population_density !== null
  );

  let normalised_data = await normalised_metrics(
    selected_metrics,
    filtered_data,
  );
  normalised_data.sort((a: any, b: any) => a.ranking - b.ranking).reverse();
  return normalised_data;
};

export const relevant_metrics_text = (label: any, state: any) => {
  let metrics_text = typeof state === "object"
    ? state.distance && state?.distance?.toFixed(2)
    : state;
  if (label === "price" || label === "gross_yields") {
    metrics_text = currencyFormat(state);
  }
  if (label === "broadband_speed") {
    metrics_text = state + "Mbps";
  }
  return metrics_text;
};
