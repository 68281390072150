/** @format */
// auth types

export const SET_USER = "SET_USER";
export const SET_USER_AVATAR = "SET_USER_AVATAR";
export const SET_INVESTMENT = "SET_INVESTMENT";
export const LOGOUT = "LOGOUT";

export const SET_PROPERTY_DATA = "SET_PROPERTY_DATA";
export const CLEAR_PROPERTY_DATA = "CLEAR_PROPERTY_DATA";
export const SET_MAPPED_PROPERTY = "SET_MAPPED_PROPERTY";
export const SET_SELECTED_PROPERTY_DATA = "SET_SELECTED_PROPERTY_DATA";
export const SET_PROPERTY_COUNT = "SET_PROPERTY_COUNT";
export const SET_PROPERTY_FILTERS = "SET_PROPERTY_FILTERS";
export const CLEAR_PROPERTY_FILTERS = "CLEAR_PROPERTY_FILTERS";
export const SET_PROPERTY_ERROR = "SET_PROPERTY_ERROR";

export const SET_METRIC_SEARCH = "SET_METRIC_SEARCH";
export const SET_SELECTED_METRICS = "SET_SELECTED_METRICS";
export const SET_COUNTRIES_LIST = "SET_COUNTRIES_LIST";

export const CLEAR_METRIC_DATA = "CLEAR_METRIC_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const SET_METRIC_PAGE = "SET_METRIC_PAGE";

export const SET_MATCHED_MATES = "SET_MATCHED_MATES";
