import React from "react";
import Breadcrumbs from "./components/Breadcrumbs";
import Menu from "./components/Menu";
import AppComponent from "../../components/layouts/AppComponent";
import { Link } from "react-router-dom";

const PasswordUpdate = () => {
  return (
    <AppComponent dark>
      <section className="section mt-3">
        <div className="container  pb-lg-4 mb-sm-2">
          <Breadcrumbs name="Overview" />
          <div className="row">
            <Menu />
            <div className="col-lg-8 col-md-7 mb-5">
              <h1 className="h2">Password &amp; Security</h1>
              <p className="pt-1">
                Manage your password settings and secure your account.
              </p>
              <h2 className="h5">Password</h2>
              <form className="needs-validation pb-4">
                <div className="row align-items-end mb-2">
                  <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="account-password">
                      Current password
                    </label>
                    <div className="password-toggle">
                      <input
                        className="form-control"
                        type="password"
                        id="account-password"
                        required
                      />
                      <label
                        className="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          className="password-toggle-check"
                          type="checkbox"
                        />
                        <span className="password-toggle-indicator"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <Link className="d-inline-block mb-2" to="#">
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-6 mb-3">
                    <label
                      className="form-label"
                      htmlFor="account-password-new"
                    >
                      New password
                    </label>
                    <div className="password-toggle">
                      <input
                        className="form-control"
                        type="password"
                        id="account-password-new"
                        required
                      />
                      <label
                        className="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          className="password-toggle-check"
                          type="checkbox"
                        />
                        <span className="password-toggle-indicator"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label
                      className="form-label"
                      htmlFor="account-password-confirm"
                    >
                      Confirm password
                    </label>
                    <div className="password-toggle">
                      <input
                        className="form-control"
                        type="password"
                        id="account-password-confirm"
                        required
                      />
                      <label
                        className="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          className="password-toggle-check"
                          type="checkbox"
                        />
                        <span className="password-toggle-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <button className="btn btn-outline-primary" type="submit">
                  Update password
                </button>
              </form>
              <div className="border-top pt-4 mt-3"></div>
            </div>
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

export default PasswordUpdate;
