/** @format */

import { LOGOUT, SET_USER } from "./types";

const initialState = {
  isAuthenticated: false,
  user: null,
};

type Props = { type: string; payload?: any }; // Define the Action type

export default function authReducer(state = initialState, action: Props) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
