import React, { useState } from "react";
import AppComponent from "../../components/layouts/AppComponent";
import Menu from "./components/Menu";
import Breadcrumbs from "./components/Breadcrumbs";
import { updateUserInvestment } from "../../redux/actions/user.action";
import { connect } from "react-redux";
import Button from "../../components/forms/Buttons";
import LoaderComponent from "../../components/layouts/LoaderComponent";
import { toast } from "react-toastify";
import { formatDate } from "../../helpers/String";

type Props = {
  investment: any;
  update: Function;
};

const ProfileOverview = ({ investment, update }: Props) => {
  const [state, setState] = useState<any>(investment);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<any>(0);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleOnFormSubmit = () => {
    setLoading(true);
    setProgress(30);
    update(state).then(() => {
      setLoading(false);
      setProgress(100);
      toast.success("Saved");
    });
  };

  return (
    <AppComponent dark>
      <LoaderComponent
        topStyle={"100px"}
        progress={progress}
        loading={loading}
      />
      <section className="section mt-3">
        <div className="container pb-lg-4 mb-sm-2">
          <Breadcrumbs name="Overview" />
          <div className="row">
            <Menu />
            <div className="col-lg-8 col-md-7 mb-5">
              <h1 className="h2 mb-1">Investment Preferences</h1>
              <p className=" mb-5">
                These details describe my characteristics as an investor.
              </p>
              <div className="row">
                <div className="col-lg-8 col-md-7 mb-5">
                  <div className="border rounded-3 p-3 mb-4" id="personal-info">
                    <div className="border-bottom pb-3 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Preferred Share
                          </label>
                          <div id="name-value">
                            {state?.preferred_share
                              ? state.preferred_share
                              : "Not Specified"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#name-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="name-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <select
                          className="form-control border-0 custom-range"
                          name="preferred_share"
                          onChange={handleOnChange}
                          value={state?.preferred_share}
                        >
                          <option value="Majority">Majority</option>
                          <option value="50/50">50/50</option>
                          <option value="Minority">Minority</option>
                          {!state?.preferred_share && (
                            <option selected value="">
                              Choose preferred option
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Open To Network
                          </label>
                          <div id="email-value">
                            {state?.open_to_network ? "Yes" : "No"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#email-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="email-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            id="form-switch-1"
                            type="checkbox"
                            name="open_to_network"
                            value={
                              state?.open_to_network === "true"
                                ? "false"
                                : "true"
                            }
                            onChange={handleOnChange}
                            checked={state?.open_to_network ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Years of Experience{" "}
                          </label>
                          <div id="phone-value">
                            {investment?.years_of_experience
                              ? investment.years_of_experience
                              : "Not Specified"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#phone-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="phone-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <input
                          className="form-control mt-3"
                          type="number"
                          onChange={handleOnChange}
                          name="years_of_experience"
                          data-bs-binded-element="#phone-value"
                          data-bs-unset-value="Not specified"
                          value={state?.years_of_experience}
                        />
                      </div>
                    </div>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Risk Tolerance
                          </label>
                          <div id="tolerance-value">
                            {state?.risk_tolerance
                              ? state.risk_tolerance
                              : "Not Specified"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#tolerance-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="tolerance-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <select
                          name="risk_tolerance"
                          className="form-control"
                          onChange={handleOnChange}
                          id=""
                        >
                          <option value="Very Risk-Taking">
                            Very Risk-Taking
                          </option>
                          <option value="Risk-Taking">Risk-Taking</option>
                          <option value="Average Risk Tolerance">
                            Average Risk Tolerance
                          </option>
                          <option value="Risk-Averse">Risk-Averse</option>
                          <option value=" Very Risk-Averse">
                            {" "}
                            Very Risk-Averse
                          </option>
                          {!state?.risk_tolerance && (
                            <option selected value="">
                              Choose preferred option
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Available to Invest From
                          </label>
                          <div id="company-value">
                            {investment?.invest_from
                              ? formatDate(investment.invest_from)
                              : "Not Specified"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#company-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="company-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <input
                          onChange={handleOnChange}
                          name="invest_from"
                          className="form-control mt-3"
                          type="date"
                          data-bs-binded-element="#company-value"
                          data-bs-unset-value="Not specified"
                          placeholder="Enter company name"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="pe-2">
                          <label className="form-label fw-bold">
                            Usable Bankroll
                          </label>
                          <div id="address-value">
                            {investment?.usable_bankroll
                              ? investment.usable_bankroll
                              : "Not Specified"}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" title="Edit">
                          <a
                            className="nav-link py-0"
                            href="#address-collapse"
                            data-bs-toggle="collapse"
                          >
                            <i className="fi-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        id="address-collapse"
                        data-bs-parent="#personal-info"
                      >
                        <input
                          name="usable_bankroll"
                          onChange={handleOnChange}
                          className="form-control mt-3"
                          type="number"
                          data-bs-binded-element="#address-value"
                          data-bs-unset-value="Not specified"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between border-top mt-4 pt-4 pb-1">
                    <Button
                      className="btn btn-primary px-3 px-sm-4"
                      type="button"
                      onClick={handleOnFormSubmit}
                      loading={loading}
                    >
                      Save changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AppComponent>
  );
};

const MapStateToProps = (state: any) => ({
  investment: state.user.investment,
});

const MapDispatchToProps = (dispatch: any) => ({
  update: (data: any) => dispatch(updateUserInvestment(data)),
});

export default connect(MapStateToProps, MapDispatchToProps)(ProfileOverview);
